import { VolumeUpRounded } from '@mui/icons-material';
import { Box, FormControl, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { sendVibration } from '../../constants/vibrate';
import Char from '../../generals/Char';
import { TTS } from '../../generals/speak';
import { theme } from '../../generals/theme';
import AnswerButtonV2 from '../AnswerButtonV2';
import QuestionText from './QuestionText';
import SelectableChips from './SelectableChips';
import SelectedChips from './SelectedChips';

interface Props {
    data: any;
    next: () => void;
    translations: any;
    baseLanguage: string;
    targetLanguage: string;
    contentPosition: any;
    answer: string;
    answerDisabledState: boolean;
    lection: string;
    convertChipToSyllable: (chip: string) => string;
    selectedChips: string[];
    speak: (text: string, language: string) => void;
    setSelectedChips: (chips: string[]) => void;
    handleDeleteChip: (chip: string) => void;
    handleAnswer: () => void;
    handleAddChip: (chip: string) => void;
    skipQuestion: () => void;
}

function GrammarQuestion(props: Props) {
    const [isAnswered, setIsAnswered] = useState(false);

    const handleAnswer = () => {
        setIsAnswered(true);
        props.handleAnswer();
    };

    useEffect(() => {
        setIsAnswered(false);
    }, [props.data]);

    return (
        <Box
            sx={props.contentPosition}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                    width: '100%',
                }}
            >
                <Typography
                    variant="h4"
                    gutterBottom
                    fontWeight='bold'
                    color='text.primary'
                >
                    {props.translations.fillInTheGap}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Char
                        language={props.targetLanguage}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: 2,
                            backgroundColor: theme.palette.grey[100],
                            paddingY: 1,
                            paddingLeft: 2,
                            paddingRight: 4,
                            ml: 2,
                            position: 'relative',
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                sendVibration('simple');
                                TTS(props.data.text, props.targetLanguage);
                            }}
                            color='primary'
                            sx={{
                                cursor: 'pointer',
                            }}
                        >
                            <VolumeUpRounded />
                        </IconButton>
                        <QuestionText
                            question={props.data.text}
                            questionId={props.data.id}
                            entireTransliteration={props.data.text}
                            lection={props.lection}
                            direction='target'
                            entireTranslation={props.data.answer}
                            language={props.baseLanguage}
                            baseLanguage={props.targetLanguage}
                            showTranslation={isAnswered}
                            parts={null}
                        />
                    </Box>
                </Box>
            </Box>
            <FormControl fullWidth>
                <SelectedChips
                    setSelectedChips={props.setSelectedChips}
                    selectedChips={props.selectedChips}
                    convertChipToSyllable={props.convertChipToSyllable}
                    handleDeleteChip={props.handleDeleteChip}
                    deletable={true}
                    draggable={true}
                    handleAddChip={props.handleAddChip}
                    showPinYin={props.targetLanguage === 'chinese' || props.targetLanguage === 'cantonese'}
                    language={props.targetLanguage}
                />
            </FormControl>
            <FormControl fullWidth>
                <SelectableChips
                    answerDisabledState={props.answerDisabledState || props.selectedChips.length !== 0}
                    handleDeleteChip={props.handleDeleteChip}
                    handleAddChip={props.handleAddChip}
                    selectedChips={props.selectedChips}
                    question={props.data}
                    showPinYin={props.targetLanguage === 'chinese' || props.targetLanguage === 'cantonese'}
                    language={props.targetLanguage}
                />
            </FormControl>
            <AnswerButtonV2
                answer={handleAnswer}
                currentAnswer={props.answer}
                translations={props.translations}
                baseLanguage={props.baseLanguage}
                targetLanguage={props.targetLanguage}
                speak={props.speak}
                skipQuestion={props.skipQuestion}
                correctAnswer={props.data.answer}
            />
        </Box>
    );
}

export default GrammarQuestion;
