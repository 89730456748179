import { Badge, Box, Container, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useIsMobile from './constants/useIsMobile';
import FunFact from './generals/FunFact';
import useEndpoint from './generals/useEndpoint';
import useTranslationState from './generals/useTranslationState';
import LearningPlan from './LearningPlan';
import { LectionModel } from './models/LectionModel';
import LectionsResponse from './responses/Lections';
import { Lections2EndpointGetHook } from './routes/Lections';
import Today from './Today';
import Alphabet from './courses/Alphabet';
import VocabsOverview from './vocabs/VocabsOverview';
import PhasesCountResponse from './responses/PhasesCount';
import { PhasesCount2EndpointGetPromise } from './routes/PhasesCount';
import EmptyResponseType from './responses/EmptyResponseType';
import EmptyResponse from './responses/EmptyResponse';
import CourseSwitcherPlain from './courses/CourseSwitcherPlain';
import NewSignup from './generals/NewSignup';
import PasswordSetModal from './PasswordSetModal';

interface Props {
    course: string;
    setCourse: (course: string) => void;
    stateState: any;
    train?: boolean;
}

function LectionsV2(props: Props) {
    const [train, setTrain] = useState<boolean>(props.train ?? false);

    const { section } = useParams();

    const translations = useTranslationState({
        email: '',
        later: '',
        password: '',
        passwordLength: '',
        wrongCredentials: '',
        emailInvalid: '',
        lections: '',
        addLection: '',
        addCourse: '',
        noCoursesAdded: '',
        notAvailable: '',
        difficultyLabel: '',
        quiz: '',
        of: '',
        completed: '',
        locked: '',
        thisLessonIsLocked: '',
        thisLessonIsCompleted: '',
        thisLessonIsAvailable: '',
        recap: '',
        youAreRecappingThisLesson: '',
        moreLections: '',
        moreLectionsSubText: '',
        setPassword: '',
        repeatPassword: '',
        lettersAndSounds: '',
        exploreLettersAndSounds: '',
        section: '',
        signup: '',
        createAnAccountInOrderToNotLooseYourProgress: '',
        createAccount: '',
        onetimepin: '',
        resendToken: '',
        funFact: '',
        skipToHere: '',
        doAQuickTestAndSkipToHere: '',
        skip: '',
        name: '',
        setAPassword: '',
        passwordsDoNotMatch: '',
        passwordsMatch: '',
        today: '',
        learningPlan: '',
        lectionX: '',
        vocab: '',
        priorLections: '',
        comingUp: '',
        startLesson: '',
        continueLearning: '',
        alphabet: '',
    });

    const isMobile = useIsMobile();
    const navigate = useNavigate();

    const [lectionsState, setLectionsState] = useEndpoint<LectionsResponse>(Lections2EndpointGetHook(props.course));

    const [todayLection, setTodayLection] = useState<LectionModel | null>(null);
    const [todayLectionIndex, setTodayLectionIndex] = useState<number>(0);

    const [phasesCountState, setPhasesCountState] = useState<PhasesCountResponse | EmptyResponseType>(EmptyResponse);

    useEffect(() => {
        if (lectionsState.status === 200) {
            const promise = PhasesCount2EndpointGetPromise(lectionsState.data.baseLanguage, lectionsState.data.targetLanguage);

            promise.then((response) => {
                setPhasesCountState(response);
            });

            for (let i = lectionsState.data.lections.length - 1; i >= 0; i--) {
                if (lectionsState.data.lections[i].isAvailable === true) {
                    setTodayLection(lectionsState.data.lections[i]);
                    setTodayLectionIndex(i + 1);

                    break;
                }
            }
        }
    }, [lectionsState]);

    const [tab, setTab] = useState<string>(section ?? 'today');

    useEffect(() => {
        if (!window.location.pathname.endsWith('/train')) {
            setTrain(false);
        }
    }, [tab]);

    const switchTab = (tab: string) => {
        setTab(tab);
        // Change the URL to the new tab /home/:tab

        window.history.pushState({}, '', `/home/${tab}`);
    };


    // Add shortcuts for 1-4 for the tabs today, learningPlan, vocab, alphabet
    useEffect(() => {
        if (lectionsState.status === 200 && !hasNoAccount()) {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === '1') {
                    switchTab('today');
                }
                if (event.key === '2') {
                    switchTab('learningPlan');
                }
                if (event.key === '3') {
                    switchTab('vocab');
                }
                if (event.key === '4') {
                    switchTab('alphabet');
                }
            };

            window.addEventListener('keydown', handleKeyDown);

            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [lectionsState]);

    const hasNoAccount = () => {
        if (lectionsState.status !== 200) {
            return false;
        }

        return lectionsState.userState.userModel.role === 'guest';
    };

    const hasAlmostAccountButNotVerified = () => {
        if (lectionsState.status !== 200) {
            return false;
        }

        return lectionsState.userState.userModel.role === 'guest' && lectionsState.userState.userModel.email !== 'anon@lairner.app';
    };

    const getEmail = () => {
        if (lectionsState.status !== 200) {
            return '';
        }

        if (lectionsState.userState.userModel.email === 'anon@lairner.app') {
            return '';
        }

        return lectionsState.userState.userModel.email;
    };

    return (
        <Box
            sx={{
                minHeight: 'calc(100vh - 144px)',
                width: '100%',
            }}
        >
            {lectionsState.status === 200 && hasNoAccount() &&
                <NewSignup
                    currentLanguageState={translations.email}
                    translations={translations}
                    askForToken={hasAlmostAccountButNotVerified()}
                    email={getEmail()}
                />
            }
            {lectionsState.status === 404 &&
                <>
                    <Typography variant='h4' gutterBottom>
                        {translations.noCoursesAdded}
                    </Typography>
                    <CourseSwitcherPlain
                        translations={translations}
                        updateCourses={() => { window.location.reload(); }}
                        setCourse={props.setCourse}
                    />
                </>
            }
            {lectionsState.status === 200 &&
                <Container
                    maxWidth='lg'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        pt: { xs: 1, md: 4 },
                        pb: 2,
                    }}
                >
                    <Typography
                        variant='h4'
                        sx={{
                            mb: 2,
                        }}
                    >
                        {translations.continueLearning}
                    </Typography>
                    <Box
                        sx={{
                            width: { xs: '100%', md: 500 },
                        }}
                    >
                        <Tabs
                            value={tab}
                            onChange={(event, newValue) => switchTab(newValue)}
                            indicatorColor='primary'
                            textColor='primary'
                            variant='fullWidth'
                        >
                            <Tab
                                label={translations.today}
                                value='today'
                            />
                            <Tab
                                label={translations.lections}
                                value='learningPlan'
                            />
                            {!isMobile && phasesCountState.status === 200 &&
                                [
                                    <Tab
                                        label={<>
                                            {translations.vocab}
                                            <Badge
                                                badgeContent={phasesCountState.data.dueCount}
                                                color='primary'
                                                sx={{
                                                    position: 'absolute',
                                                    top: 11,
                                                    left: '70%',
                                                }}
                                            >
                                            </Badge>
                                        </>}
                                        value='vocab'
                                    />,
                                    <Tab
                                        label={translations.alphabet}
                                        value='alphabet'
                                    />
                                ]
                            }
                        </Tabs>
                    </Box>
                    {tab === 'today' && lectionsState.status === 200 && todayLection !== null &&
                        <Today
                            baseLanguage={lectionsState.data.baseLanguage}
                            targetLanguage={lectionsState.data.targetLanguage}
                            todayLection={todayLection as any}
                            todayLectionIndex={todayLectionIndex}
                            setTab={setTab}
                            setTrain={setTrain}
                            vocabCount={phasesCountState.status === 200 ? phasesCountState.data.dueCount : 0}
                        />
                    }
                    {tab === 'learningPlan' && lectionsState.status === 200 && todayLection !== null &&
                        <LearningPlan
                            todayLectionIndex={todayLectionIndex}
                            lectionsState={lectionsState}
                            translations={translations}
                        />
                    }
                    {tab === 'alphabet' &&
                        <Alphabet />
                    }
                    {tab === 'vocab' &&
                        <VocabsOverview
                            baseLanguage={lectionsState.data.baseLanguage}
                            targetLanguage={lectionsState.data.targetLanguage}
                            setTab={setTab}
                            train={train}
                            setTrain={setTrain}
                        />
                    }
                </Container>
            }
            {lectionsState.status === 200 && lectionsState.data.lections.length > 0 && !isMobile &&
                <FunFact
                    baseLanguage={lectionsState.data.baseLanguage}
                    targetLanguage={lectionsState.data.targetLanguage}
                />
            }
            {props.stateState.data.needsPassword && !hasNoAccount() &&
                <PasswordSetModal
                    translations={translations}
                />
            }
        </Box>
    );
}

export default LectionsV2;
