import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function Activities2EndpointGetHook(
    param_lastDays: string, 
) {
    return GetWithHook(routes.Activities, {
        lastDays: param_lastDays, 
    });
}

export function Activities2EndpointGetPromise(
    param_lastDays: string, 
) {
    return GetWithPromise(routes.Activities, {
        lastDays: param_lastDays, 
    });
}

