import { Box, Typography } from '@mui/material';
import useIsMobile from '../../constants/useIsMobile';
import Char from '../../generals/Char';
import { theme } from '../../generals/theme';
import AnswerButtonV2 from '../AnswerButtonV2';

interface Props {
    data: any;
    next: () => void;
    translations: any;
    baseLanguage: string;
    targetLanguage: string;
}

function Motivation(props: Props) {
    const isMobile = useIsMobile();

    const height = isMobile ? '100%' : '90vh';

    const isNoMenu = window.location.href.includes('nomenu');

    return (
        <Box
            sx={{
                width: '100%',
                height: isNoMenu ? 'calc(100vh - 200px)' : 'calc(100vh - 100px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                textAlign: 'center',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '0.1px solid #c4c4c4',
                    borderRadius: 4,
                    backgroundColor: theme.palette.grey[50],
                    paddingY: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                    transform: 'translateY(-10px)',
                    mr: 2,
                    // Add a triangle to the right of the box
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        bottom: '10%',
                        right: '-10px',
                        transform: 'translateY(-50%)',
                        width: 0,
                        height: 0,
                        borderLeft: '10px solid ' + theme.palette.grey[200],
                        borderTop: '10px solid transparent',
                        borderBottom: '10px solid transparent',
                    },
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        padding: 1,
                        mr: 2,
                    }}
                >
                    {props.data.question}
                </Typography>
            </Box>
            <Box
            >
                <Char
                    language={props.baseLanguage}
                />
            </Box>
            <AnswerButtonV2
                translations={props.translations}
                currentAnswer="corrrrreeeect"
                answer={props.next}
                answerText={props.translations.next}
                baseLanguage={props.baseLanguage}
                targetLanguage={props.targetLanguage}
                speak={() => { }}
                isMotivational={true}
                correctAnswer="corrrrreeeect"
            />
        </Box >
    );
}

export default Motivation;
