import { createTheme } from '@mui/material';

export const dark = createTheme({
    palette: {
        divider: '#AAAAAA',
        mode: 'dark',
        primary: {
            light: '#AEBDF4',
            main: '#3277e5',
            dark: '#1e4bb3',
            contrastText: '#ffffff',
        },
        text: {
            primary: '#ffffff',
            secondary: '#ffffff',
            disabled: '#bbbbbb',
        },
        secondary: {
            light: '#F9A3B0',
            main: '#EC5272',
            dark: '#AE0843',
        },
        background: {
            default: '#000000',
            paper: '#0a0a0a',
        },
        error: {
            main: '#ba000d',
            dark: '#7f0008',
            light: '#e3515c',
        },
        success: {
            main: '#4caf50',
            dark: '#e0ffe0',
            light: '#065e12',
        },
        grey: {
            // 50: '#f9f9f9',
            // 100: '#f2f2f2',
            // 200: '#e6e6e6',
            // 300: '#cccccc',
            // 400: '#b3b3b3',
            // 500: '#999999',
            // 600: '#AAAAAA',
            // 700: '#4d4d4d',
            // 800: '#333333',
            // 900: '#1a1a1a',
            // Same thing, but reverse for dark mode
            50: '#1a1a1a',
            100: '#2b2b2b',
            200: '#333333',
            300: '#4d4d4d',
            400: '#666666',
            500: '#808080',
            600: '#666666',
            700: '#b3b3b3',
            800: '#cccccc',
            900: '#f2f2f2',
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#ffffff',
                        },
                    },
                    '&:hover .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#ffffff',
                        },
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                        '& fieldset': {
                            borderColor: '#ffffff',
                        },
                    },
                    '& .MuiInputLabel-outlined.Mui-focused': {
                        color: '#ffffff',
                    },
                    // Placeholder and label white
                    '& .MuiInputLabel-root': {
                        color: '#ffffff',
                    },
                    '& .MuiInputBase-input': {
                        color: '#ffffff',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#AAAAAA',
                        },
                    },
                    '&:hover .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#AAAAAA',
                        },
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                        '& fieldset': {
                            borderColor: '#AAAAAA',
                        },
                    },
                    '& .MuiInputLabel-outlined.Mui-focused': {
                        color: '#AAAAAA',
                    }
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    '&.Mui-disabled': {
                        color: '#cccccc',
                    },
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: '1.3rem',
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: '#000000',
                }
            }
        },
    },
    typography: {
        allVariants: {
            color: '#ffffff',
            fontFamily: 'San Francisco, Arial, sans-serif',
            fontWeight: 300,
        },
    },
});

