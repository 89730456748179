import { Alert, Button, FormControl, TextField } from '@mui/material';
import { useState } from 'react';
import LairnerModal from './generals/LairnerModal';
import { Password2EndpointPostPromise } from './routes/Password';

interface Props {
    translations: any;
}

function PasswordSetModal(props: Props) {
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [open, setOpen] = useState(true);

    const changePassword = () => {
        const promise = Password2EndpointPostPromise('', password, password2);

        promise.then((response) => {
            if (response.status === 200) {
                response.json().then((response: any) => {
                    if (response.data.success) {
                        setOpen(false);
                    } else {
                        window.location.reload();
                    }
                });
            }
        });
    };

    return (
        <LairnerModal
            open={open}
            title={props.translations.setAPassword}
        >
            <TextField
                label={props.translations.password}
                variant='outlined'
                fullWidth
                sx={{
                    marginBottom: 2,
                }}
                type='password'
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        changePassword();
                    }
                }}
            />
            <TextField
                label={props.translations.repeatPassword}
                variant='outlined'
                fullWidth
                sx={{
                    marginBottom: 2,
                }}
                type='password'
                value={password2}
                onChange={(event) => setPassword2(event.target.value)}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        changePassword();
                    }
                }}
            />
            {password !== password2 && password.length > 0 && password2.length > 0 &&
                <Alert
                    severity='error'
                    sx={{
                        marginBottom: 2,
                    }}
                >
                    {props.translations.passwordsDoNotMatch}
                </Alert>
            }
            {password === password2 && password.length > 0 &&
                <Alert
                    severity='success'
                    sx={{
                        marginBottom: 2,
                    }}
                >
                    {props.translations.passwordsMatch}
                </Alert>
            }
            <FormControl fullWidth>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={changePassword}
                >
                    {props.translations.setPassword}
                </Button>
            </FormControl>
        </LairnerModal>
    );
}

export default PasswordSetModal;
