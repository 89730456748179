import { Box, Chip, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { theme } from './generals/theme';
import useEndpoint from './generals/useEndpoint';
import useTranslationState from './generals/useTranslationState';
import HomeFeature from './HomeFeature';
import JoinV2 from './JoinV2';
import StateResponse from './responses/State';
import { State2EndpointGetHook } from './routes/State';

function AnonymousHomepage() {
    const translations = useTranslationState({
        theEasyWayToLearnALanguage: '',
        continue: '',
        ourFeatures: '',
        new: '',
        password: '',
        wrongCredentials: '',
        email: '',
        token: '',
        youReceivedAnEmail: '',
        emailText: '',
        chooseLanguage: '',
        welcome: '',
        language: '',
        sendEmail: '',
        verifyToken: '',
        english: '',
        german: '',
        spanish: '',
        italian: '',
        dutch: '',
        portuguese: '',
        french: '',
        ialreadyhaveanaccount: '',
        weHaveLotsOfCourses: '',
        iWantToLearn: '',
        getStarted: '',
        login: '',
        resendToken: '',
        onetimepin: '',
        VariousLearningMethodsWillDriveYouText: '',
        noCoursesAvailableWithThisStartingLanguage: '',
        weAlsoHaveTheseCoursesFromOtherBaseLanguages: '',
        baseLanguageColon: '',
        // Features
        variousLearningMethods: '',
        variousLearningMethodsText: '',
        personalizedCourses: '',
        personalizedCoursesText: '',
        vocabTrainer: '',
        vocabTrainerText: '',
        missions: '',
        missionsText: '',
        hundredsOfCourses: '',
        hundredsOfCoursesText: '',
        freeForever: '',
        freeForeverText: '',
        imprint: '',
        privacy: '',
    });

    const [stateState, setStateState] = useEndpoint<StateResponse>(State2EndpointGetHook());

    const navigate = useNavigate();

    if (stateState.status === 200 && stateState.userState.isLogin === true && stateState.userState.userModel.isVerified === true) {
        navigate('/home/today');
    }

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: 500,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        zIndex: 0,
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'url(https://picsum.photos/id/115/4020/2080)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            backdropFilter: 'blur(4px) brightness(0.8)',
                        },
                    }}
                >
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                        zIndex: 1,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Container maxWidth='lg'>
                        <Typography
                            variant='h4'
                            sx={{
                                fontWeight: 'bold',
                                mb: 2,
                                textAlign: 'center',
                                color: 'white',
                            }}
                        >
                            {translations.theEasyWayToLearnALanguage}
                        </Typography>
                        <JoinV2
                            translations={translations}
                            stateState={stateState}
                        />
                    </Container>
                </Box>
            </Box>
            <Container
                maxWidth='lg'
                sx={{
                    mt: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 4,
                    }}
                >
                    <Chip
                        label={translations.new}
                        sx={{
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                        }}
                    />
                    <Typography
                        variant='h4'
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        {translations.ourFeatures}
                    </Typography>
                </Box>
                <Grid
                    container
                    spacing={2}
                >
                    <HomeFeature
                        img='https://picsum.photos/id/1011/500/500'
                        title={translations.variousLearningMethods}
                        description={translations.variousLearningMethodsText}
                    />
                    <HomeFeature
                        img='https://picsum.photos/id/1012/500/500'
                        title={translations.personalizedCourses}
                        description={translations.personalizedCoursesText}
                    />
                    <HomeFeature
                        img='https://picsum.photos/id/1013/500/500'
                        title={translations.vocabTrainer}
                        description={translations.vocabTrainerText}
                    />
                    <HomeFeature
                        img='https://picsum.photos/id/1014/500/500'
                        title={translations.missions}
                        description={translations.missionsText}
                    />
                    <HomeFeature
                        img='https://picsum.photos/id/1015/500/500'
                        title={translations.hundredsOfCourses}
                        description={translations.hundredsOfCoursesText}
                    />
                    <HomeFeature
                        img='https://picsum.photos/id/1016/500/500'
                        title={translations.freeForever}
                        description={translations.freeForeverText}
                    />
                </Grid>
            </Container>
            <Footer />
        </Box>
    );
}

export default AnonymousHomepage;
