import { Box, Typography } from '@mui/material';
import useIsMobile from '../../constants/useIsMobile';
import { theme } from '../../generals/theme';
import useTranslationState from '../../generals/useTranslationState';
import SelectChip from './SelectChip';

interface Props {
    selectedChips: string[];
    convertChipToSyllable: (id: string) => string;
    setSelectedChips?: (chips: string[]) => void;
    handleDeleteChip?: (id: string) => void;
    handleAddChip?: (id: string) => void;
    deletable?: boolean;
    draggable?: boolean;
    showPinYin?: boolean;
    language: string;
}

function SelectedChips(props: Props) {
    const getLetterForIndexLowerCase = (num: number) => String.fromCharCode(97 + num);
    const isMobile = useIsMobile();

    const translations = useTranslationState({
        selectChipsToWriteYourAnswer: '',
        dropChipsHere: '',
    });

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                backgroundColor: theme.palette.grey[50],
                borderRadius: 2,
                p: 0.8,
                marginTop: 1,
                minHeight: 70,
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.dataTransfer.dropEffect = 'move';
            }}
            onDrop={(e) => {
                e.preventDefault();

                if (props.setSelectedChips) {
                    const chipId = localStorage.getItem('draggedChip');

                    if (chipId && props.handleAddChip && !props.selectedChips.includes(chipId)) {
                        props.handleAddChip(chipId);
                    } else if (chipId && props.handleDeleteChip && props.selectedChips.includes(chipId)) {
                        props.handleDeleteChip(chipId);
                    }
                }
            }}
        >
            {props.selectedChips.length === 0 &&
                <Typography
                    variant="body1"
                    sx={{
                        color: theme.palette.grey[400],
                    }}
                >
                    {isMobile ? translations.selectChipsToWriteYourAnswer : translations.dropChipsHere}
                </Typography>
            }
            {!props.deletable && props.selectedChips.map((chip) => (
                <SelectChip
                    id={chip}
                    text={props.convertChipToSyllable(chip)}
                    onDragStart={(e: any) => {
                        if (props.draggable) {
                            localStorage.setItem('draggedChip', chip);
                        }
                    }}
                    selected
                    hotKey={getLetterForIndexLowerCase(props.selectedChips.indexOf(chip))}
                    draggable={props.draggable !== false}
                    language={props.language}
                    showPinYin={props.showPinYin}
                    sx={{
                        height: 'fit-content',
                    }}
                />
            ))}
            {props.deletable && props.selectedChips.map((chip) => (
                <SelectChip
                    id={chip}
                    onDelete={() => {
                        if (props.handleDeleteChip) {
                            props.handleDeleteChip(chip);
                        }
                    }}
                    text={props.convertChipToSyllable(chip)}
                    onDragStart={(e: any) => {
                        if (props.draggable) {
                            localStorage.setItem('draggedChip', chip);
                        }
                    }}
                    sx={{
                        height: 'fit-content',
                    }}
                    selected
                    language={props.language}
                    hotKey={getLetterForIndexLowerCase(props.selectedChips.indexOf(chip))}
                    draggable={props.draggable !== false}
                    showPinYin={props.showPinYin}
                />
            ))}
        </Box>
    );
}

export default SelectedChips;
