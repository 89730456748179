import { KeyboardReturn } from '@mui/icons-material';
import { Box, Button, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { appleButton } from '../constants/appleButton';
import useIsMobile from '../constants/useIsMobile';

interface Props {
    translations: any;
    currentAnswer: string;
    answer: () => void;
    answerText?: string;
    baseLanguage: string;
    targetLanguage: string;
    speak: (text: string, language: string) => void;
    disabledOverride?: boolean;
    skipQuestion?: () => void;
    isMotivational?: boolean;
    isReverse?: boolean;
    correctAnswer: string;
}

function AnswerButtonV2(props: Props) {
    const { lection } = useParams();

    const [hasBeenClicked, setHasBeenClicked] = useState<boolean>(false);
    const isMobile = useIsMobile();
    // If the button is not disabled, add a listener to enter key to trigger the answer function

    const disabled = props.disabledOverride === false || props.currentAnswer === '';

    // Event listener for enter key
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && !disabled && !hasBeenClicked) {
                event.preventDefault();
                props.answer();
                speakIfNecessary();
                setHasBeenClicked(true);
            }
        };

        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [disabled, props.answer]);

    useEffect(() => {
        setHasBeenClicked(false);
    }, [props.currentAnswer]);

    const speakIfNecessary = () => {
        if (props.isReverse) {
            return;
        }

        const languagesThatDoNotUseSpaces = [
            'japanese',
            'chinese',
            'thai',
            'khmer',
            'lao',
            'burmese',
            'tibetan',
            'dzongkha',
        ];

        if (props.currentAnswer.trim().split(' ').length > 1 || languagesThatDoNotUseSpaces.includes(props.targetLanguage)) {
            props.speak(props.correctAnswer, props.targetLanguage);
        }
    };

    const isNoMenu = window.location.href.includes('nomenu');

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1300,
                width: '100vw',
                height: { xs: 'auto', md: '15vh' },
                backgroundColor: useTheme().palette.background.default,
                display: 'flex',
                justifyContent: 'space-around',
                paddingY: 2,
                paddingX: 3,
                paddingBottom: isNoMenu ? 7 : 3,
                gap: 1,
                alignItems: { md: 'center', xs: 'flex-end' },
                boxSizing: 'border-box',
                borderTop: isMobile ? 'none' : '0.1px solid ' + useTheme().palette.primary.light,
            }}
        >
            {props.skipQuestion && lection !== 'intro' &&
                <Button
                    variant='text'
                    sx={{
                        cursor: 'pointer',
                        width: 'fit-content',
                        height: 40,
                        borderRadius: 2,
                        position: { md: 'absolute', xs: 'relative' },
                        top: { md: '50%', xs: 'auto' },
                        left: { md: 200, xs: 'auto' },
                        transform: { md: 'translateY(-50%)', xs: 'none' },
                    }}
                    onClick={props.skipQuestion}
                >
                    {props.translations.skip}
                </Button>
            }
            <Button
                variant='contained'
                color='primary'
                sx={{
                    cursor: props.currentAnswer === '' ? 'not-allowed' : 'pointer',
                    width: isMobile ? '100%' : 200,
                    maxWidth: { xs: 'auto', md: '60vw' },
                    position: { xs: 'relative', md: 'absolute' },
                    top: { md: '50%', xs: 'auto' },
                    right: { md: 200, xs: 'auto' },
                    transform: { md: 'translateY(-50%)', xs: 'none' },
                    ...appleButton,
                }}
                disabled={props.disabledOverride === false || props.currentAnswer === ''}
                onClick={
                    () => {
                        if (props.currentAnswer !== '') {
                            setHasBeenClicked(true);
                            speakIfNecessary();
                            props.answer();
                        }
                    }
                }
            >
                {props.answerText ?? props.translations.answer}
                {!isMobile &&
                    <KeyboardReturn
                        sx={{
                            ml: 1,
                        }}
                    />
                }
            </Button>
        </Box>
    );
}

export default AnswerButtonV2;

