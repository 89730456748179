import { Box, Link, Typography } from '@mui/material';
import useIsMobile from './constants/useIsMobile';
import { theme } from './generals/theme';
import useEndpoint from './generals/useEndpoint';
import useTranslationState from './generals/useTranslationState';
import StateResponse from './responses/State';
import { State2EndpointGetHook } from './routes/State';

function Footer() {
    const translations = useTranslationState({
        privacy: '',
        imprint: '',
    });

    const [stateState, setStateState] = useEndpoint<StateResponse>(State2EndpointGetHook());

    const isMobile = useIsMobile();

    return (
        <>
            {stateState.status === 200 && (!isMobile || !stateState.userState.isLogin) &&
                <Box
                    sx={{
                        minHeight: 80,
                        backgroundColor: theme.palette.primary.main,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingX: 2,
                        color: 'white',
                    }}
                >
                    <Box>
                        <Link
                            href="/imprint"
                            color="inherit"
                            sx={{
                                marginRight: 2,
                                textDecoration: 'none',
                            }}
                        >
                            {translations.imprint}
                        </Link>
                        <Link
                            href="/privacy"
                            color="inherit"
                            sx={{
                                marginRight: 2,
                                textDecoration: 'none',
                            }}
                        >
                            {translations.privacy}
                        </Link>
                    </Box>
                    <Typography variant="body2" color="inherit">
                        © 2024 Alexander Bösing Lairner GbR
                    </Typography>
                </Box>
            }
        </>
    );
}

export default Footer;
