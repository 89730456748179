import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useIsMobile from '../../constants/useIsMobile';
import { theme } from '../../generals/theme';
import pinyin from 'chinese-to-pinyin';
import { transliterate } from 'transliteration';
import { TRANSLITERATIONS } from '../../constants/transliterations';
import { sendVibration } from '../../constants/vibrate';

interface Props {
    text: string;
    onDelete?: any;
    onClick?: any;
    disabled?: boolean;
    id: string;
    sx?: any;
    onDragStart?: any;
    color?: string | undefined;
    hotKey?: string;
    selected?: boolean;
    isMatching?: boolean;
    draggable?: boolean;
    disabledButVisible?: boolean;
    showPinYin?: boolean;
    language: string;
}

function SelectChip(props: Props) {
    const doNotShow = props.disabled && props.selected;
    const [isDragging, setIsDragging] = useState(false);

    const isMobile = useIsMobile();

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.key === props.hotKey) {
                if (props.onClick && props.disabled !== true && !props.selected) {
                    props.onClick(props.id);
                } else if (props.onDelete && props.disabled !== true && props.selected) {
                    props.onDelete(props.id);
                }
            }
        };

        document.addEventListener('keydown', listener);

        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [props.hotKey, props.onClick, props.disabled, props.id]);

    return (
        <>
            <Box
                onClick={() => {
                    if (props.selected !== true) {
                        if (props.onClick && props.disabled !== true && props.disabledButVisible !== true) {
                            sendVibration('simple');
                            props.onClick(props.id);
                        }
                    } else {
                        if (props.onDelete && props.disabled !== true && props.disabledButVisible !== true) {
                            sendVibration('simple');
                            props.onDelete(props.id);
                        }
                    }
                }}
                sx={{
                    borderRadius: 3,
                    borderTop: '1px solid ' + theme.palette.primary.light,
                    borderRight: '1px solid ' + theme.palette.primary.light,
                    borderBottom: '2px solid ' + theme.palette.primary.light,
                    borderLeft: '2px solid ' + theme.palette.primary.light,
                    borderColor: doNotShow || props.disabledButVisible ? theme.palette.grey[400] : theme.palette.primary.light,
                    cursor: doNotShow || props.disabledButVisible ? 'inherit' : 'pointer',
                    color: doNotShow ? theme.palette.grey[400] : 'inherit',
                    backgroundColor: doNotShow ? theme.palette.grey[400] : 'transparent',
                    p: { xs: 1, lg: 1 },
                    boxSizing: 'border-box',
                    userSelect: 'none',
                    fontSize: { xs: '1rem', sm: '1.25rem' },
                    opacity: props.disabled ? 0.2 : 1.2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    whiteSpace: 'nowrap',
                    alignItems: 'center',
                    ...props.sx,
                }}
                onDragStart={(e) => {
                    if (props.disabled !== true && props.onDragStart && !isMobile && props.draggable !== false && props.disabledButVisible !== true) {
                        e.dataTransfer.effectAllowed = 'all';
                        props.onDragStart();
                        setIsDragging(true);
                    }
                }}
                onDragEnd={() => {
                    setIsDragging(false);
                }}
                draggable={props.disabled !== true && !isMobile && props.draggable !== false && props.disabledButVisible !== true}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        fontSize: { xs: '1rem', md: '1.25rem' },
                    }}
                >
                    {props.text}
                    <Typography
                        sx={{
                            fontSize: { xs: '0.6rem', md: '0.8rem' },
                        }}
                    >
                        {TRANSLITERATIONS.includes(props.language) &&
                            transliterate(props.text)
                        }
                        {(props.language === 'chinese' || props.language === 'cantonese') &&
                            pinyin(props.text)
                        }
                    </Typography>
                </Box>
                {props.hotKey && !isMobile && props.hotKey.length === 1 &&
                    <Typography
                        sx={{
                            color: doNotShow ? theme.palette.grey[400] : theme.palette.primary.main,
                            borderRadius: 3,
                            padding: '0.1rem 0.5rem',
                            marginLeft: props.isMatching ? 'auto' : 0,
                            fontSize: '0.8rem',
                        }}
                    >
                        {props.hotKey}
                    </Typography>
                }
            </Box>
        </>
    );
}

export default SelectChip;
