import { Box, Container, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { TTS } from '../generals/speak';
import useEndpoint from '../generals/useEndpoint';
import useTranslationState from '../generals/useTranslationState';
import { LetterModel } from '../models/LetterModel';
import AlphabetResponse from '../responses/Alphabet';
import { Alphabet2EndpointGetHook } from '../routes/Alphabet';

function Alphabet() {
    const course = localStorage.getItem('course') || '';

    const [alphabetState, setAlphabetState] = useEndpoint<AlphabetResponse>(Alphabet2EndpointGetHook(course));

    const translateCourseIntoTargetLanguage = (course: any) => {
        const split = course.split('__-__');
        return split[1];
    };

    const translationState = useTranslationState({
        letters: '',
        alphabet: '',
    });

    return (
        <Container
            maxWidth="md"
            sx={{
                paddingTop: 2,
                minHeight: 'calc(100vh - 144px)',
                paddingBottom: 12,
            }}
        >
            <Typography
                variant="h4"
                align="center"
                color="text.primary"
                gutterBottom
            >
                {translationState.alphabet}
            </Typography>
            <Grid container spacing={2}>
                {alphabetState.status === 200 && alphabetState.data.alphabet.map((letter: LetterModel) => (
                    <Grid item xs={3} md={2} key={letter.id}>
                        <Tooltip
                            title={letter.description}
                        >
                            <Box
                                sx={{
                                    backgroundColor: useTheme().palette.grey[100],
                                    boxShadow: 0,
                                    borderRadius: 2,
                                    userSelect: 'none',
                                    padding: 2,
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                }}
                                onClick={() => { TTS(letter.example, translateCourseIntoTargetLanguage(course)); }}
                            >
                                <Typography
                                    variant="h4"
                                    align="center"
                                    color="text.primary"
                                >
                                    {letter.letter}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    align="center"
                                    color="text.primary"
                                >
                                    {letter.example}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    align="center"
                                    color="text.primary"
                                    sx={{
                                        backgroundColor: useTheme().palette.grey[300],
                                        paddingX: 1,
                                        paddingY: 0.5,
                                    }}
                                >
                                    {letter.phonetic}
                                </Typography>
                            </Box>
                        </Tooltip>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default Alphabet;
