import { Container } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useEndpoint from './generals/useEndpoint';
import LectionsV2 from './LectionsV2';
import StateResponse from './responses/State';
import { State2EndpointGetHook } from './routes/State';

interface Props {
    train?: boolean;
}

function Home(props: Props) {
    const navigate = useNavigate();

    const [stateState, setStateState] = useEndpoint<StateResponse>(State2EndpointGetHook());

    if (stateState.status === 200 && stateState.userState.isLogin === false) {
        navigate('/');
    }

    if (stateState.status === 200 && stateState.userState.isLogin === true && stateState.userState.userModel.isVerified === false) {
        navigate('/');
    }

    const [course, setCourse] = useState(localStorage.getItem('course') || '');

    return (
        <Container
            maxWidth='xl'
            sx={{
                paddingTop: { xs: 1, md: 8 },
            }}
        >
            {stateState.status === 200 && stateState.userState.isLogin === true &&
                <>
                    <LectionsV2
                        course={course}
                        setCourse={setCourse}
                        stateState={stateState}
                        train={props.train}
                    />
                </>
            }
        </Container>
    );
}

export default Home;
