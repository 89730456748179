import { LockRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

interface Props {
    baseLanguage: string;
    targetLanguage: string;
    todayLection: { id: string };
    canDoRecap: () => boolean;
    translations: any;
    navigate: (url: string) => void;
}

function RecapCard(props: Props) {
    const handleClick = () => {
        if (props.canDoRecap()) {
            props.navigate(`/courses/${props.baseLanguage}/${props.targetLanguage}/${props.todayLection.id}/lesson`);
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: props.canDoRecap() ? 'primary.main' : 'grey.400',
                transition: 'background-color 0.3s',
                '&:hover': {
                    backgroundColor: props.canDoRecap() ? 'primary.dark' : 'grey.400',
                },
                borderRadius: 2,
                color: 'primary.contrastText',
                display: 'flex',
                flexDirection: 'column',
                cursor: props.canDoRecap() ? 'pointer' : 'not-allowed',
                height: 300,
                p: 2,
            }}
            onClick={handleClick}
        >
            <Typography variant="body1" sx={{ color: 'primary.contrastText' }}>
                {props.translations.recap}
            </Typography>
            {props.canDoRecap() ? (
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'primary.contrastText' }}>
                    {props.translations.nowYouWillHaveToRecapThisLectionToContinueWithTheNextOne}
                </Typography>
            ) : (
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'primary.contrastText' }}>
                    {props.translations.afterAllUnitsYouWillBeAbleToRecapThisLection}
                </Typography>
            )}
            {!props.canDoRecap() && (
                <LockRounded sx={{ fontSize: 48, color: 'primary.contrastText', marginLeft: 'auto', marginTop: 'auto' }} />
            )}
        </Box>
    );
}

export default RecapCard;
