import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function Password2EndpointGetHook(
    param_oldPassword: string, 
    param_password: string, 
    param_passwordConfirm: string, 
) {
    return GetWithHook(routes.Password, {
        oldPassword: param_oldPassword, 
        password: param_password, 
        passwordConfirm: param_passwordConfirm, 
    });
}

export function Password2EndpointGetPromise(
    param_oldPassword: string, 
    param_password: string, 
    param_passwordConfirm: string, 
) {
    return GetWithPromise(routes.Password, {
        oldPassword: param_oldPassword, 
        password: param_password, 
        passwordConfirm: param_passwordConfirm, 
    });
}

export function Password2EndpointPostPromise(
    param_oldPassword: string, 
    param_password: string, 
    param_passwordConfirm: string, 
) {
    return PostWithPromise(routes.PasswordPost, {
        oldPassword: param_oldPassword, 
        password: param_password, 
        passwordConfirm: param_passwordConfirm, 
    });
}

