import { CancelRounded, CheckCircleRounded } from '@mui/icons-material';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import useIsMobile from '../constants/useIsMobile';
import ReportDialog from './Report/ReportDialog';
import './AnswerFeedback.css';
import { stopAllAudios } from '../generals/AudioManager';
import { appleButton } from '../constants/appleButton';
import { TRANSLITERATIONS } from '../constants/transliterations';
import { transliterate } from 'transliteration';
import pinyin from 'chinese-to-pinyin';

interface Props {
    questionId?: string;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
    open: boolean;
    next: () => void;
    translations: any;
    speak: (text: string, language?: string) => void;
    correctAnswer: string;
    baseLanguage?: string;
    targetLanguage?: string;
    givenAnswer?: string;
    isReverse?: boolean;
    skipQuestion?: () => void;
}

function AnswerFeedbackV2(props: Props) {
    const isMobile = useIsMobile();

    const isNoMenu = window.location.href.includes('nomenu');

    const backgroundColors = {
        success: useTheme().palette.success.light, // A softer green background
        info: '#e0faff',    // A softer blue background
        warning: '#fff5cc',  // A softer yellow background
        error: useTheme().palette.error.light,   // A softer red background
    };

    const colors = {
        success: useTheme().palette.success.dark, // A dark green color
        info: '#004466',    // A dark blue color
        warning: '#996600',  // A dark yellow color
        error: useTheme().palette.error.dark,   // A dark red color
    };

    const [showComments, setShowComments] = useState(false);

    const toggleComments = () => {
        setShowComments(!showComments);
    };

    const [openReport, setOpenReport] = useState(false);

    const reportQuestion = () => {
        setOpenReport(true);
    };

    const [disabled, setDisabled] = useState(false);

    const isSuccess = props.severity === 'success';

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && !disabled && props.open) {
                event.preventDefault();
                stopAllAudios();
                props.next();
            }
        };

        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [disabled, props.next]);

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1300,
                width: '100vw',
                height: { xs: 'auto', md: '15vh' },
                minHeight: { xs: 'auto', md: 'auto' },
                display: props.open ? 'flex' : 'none',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingY: 2,
                paddingX: 3,
                gap: 1,
                paddingBottom: isNoMenu ? 6 : 3,
                boxSizing: 'border-box',
                flexDirection: { xs: 'column', md: 'row' },
                backgroundColor: backgroundColors[props.severity === 'success' ? 'success' : 'error'],
            }}
        >
            <Box
                sx={{
                    position: { xs: 'relative', md: 'absolute' },
                    top: { md: '50%', xs: 'auto' },
                    left: { md: 200, xs: 'auto' },
                    transform: { md: 'translateY(-50%)', xs: 'none' },
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        width: '100%',
                    }}
                >
                    {isSuccess
                        ? <CheckCircleRounded sx={{ color: colors.success, fontSize: 40 }} />
                        : <CancelRounded sx={{ color: colors.error, fontSize: 40 }} />
                    }
                    <Typography
                        sx={{
                            color: colors[props.severity],
                            fontSize: { xs: 16, md: 20 },
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        {isSuccess ? props.translations.correct : props.translations.incorrect}
                    </Typography>
                </Box>
                {!isSuccess &&
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'left',
                        }}
                    >
                        <Typography
                            sx={{
                                color: colors[props.severity],
                                fontSize: { xs: 16, md: 20 },
                                fontWeight: 'bold',
                            }}
                        >
                            {props.translations.correctAnswer}
                        </Typography>
                        <Typography
                            sx={{
                                color: colors[props.severity],
                                fontSize: { xs: 16, md: 20 },
                            }}
                        >
                            {props.correctAnswer}
                        </Typography>
                        <Typography
                            sx={{
                                color: colors[props.severity],
                                fontSize: { xs: 14, md: 18 },
                            }}
                        >
                            {TRANSLITERATIONS.includes(props.targetLanguage!) &&
                                transliterate(props.correctAnswer)
                            }
                            {(props.targetLanguage === 'chinese' || props.targetLanguage === 'cantonese') &&
                                pinyin(props.correctAnswer)
                            }
                        </Typography>
                    </Box>
                }
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-around',
                }}
            >
                <Button
                    sx={{
                        marginTop: 'auto',
                        color: 'white',
                        width: isMobile ? '100%' : 200,
                        maxWidth: { xs: 'auto', md: '60vw' },
                        position: { xs: 'relative', md: 'absolute' },
                        top: { md: '50%', xs: 'auto' },
                        right: { md: 200, xs: 'auto' },
                        transform: { md: 'translateY(-50%)', xs: 'none' },
                        ...appleButton,
                    }}
                    variant='contained'
                    color={isSuccess ? 'success' : 'error'}
                    onClick={() => {
                        stopAllAudios();
                        props.next();
                    }}
                >
                    {isSuccess ? props.translations.continue : props.translations.gotIt}
                </Button>
            </Box>
            {props.questionId &&
                <ReportDialog
                    open={openReport}
                    setOpen={setOpenReport}
                    translations={props.translations}
                    question={props.questionId}
                    answer={props.givenAnswer ?? ''}
                />
            }
        </Box>
    );
}

export default AnswerFeedbackV2;
