import { Send } from '@mui/icons-material';
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { theme } from '../../generals/theme';
import useEndpoint from '../../generals/useEndpoint';
import useTranslationState from '../../generals/useTranslationState';
import { ChatbotModel } from '../../models/ChatbotModel';
import ChatResponse from '../../responses/Chat';
import ListChatbotsResponse from '../../responses/ListChatbots';
import { Chat2EndpointGetHook, Chat2EndpointGetPromise, Chat2EndpointPostPromise } from '../../routes/Chat';
import { ListChatbots2EndpointGetHook } from '../../routes/ListChatbots';
import Message from './mission/Message';

function Chatbots() {
    const translations = useTranslationState({
        noChatbotForCourse: '',
        chatbots: '',
    });

    const [course, setCourse] = useState(localStorage.getItem('course') || '');

    const [chatbotsState, setChatbotsState] = useEndpoint<ListChatbotsResponse>(ListChatbots2EndpointGetHook('false', course));

    const [chatbotState, setChatbotState] = useState('');

    const [messagesState, setMessagesState] = useEndpoint<ChatResponse>(Chat2EndpointGetHook(chatbotState!, ''));

    useEffect(() => {
        if (chatbotState !== '') {
            const promise = Chat2EndpointGetPromise(chatbotState!, '');

            promise.then((response) => {
                setMessagesState(response);
            });
        }
    }, [chatbotState]);

    const [messageState, setMessageState] = useState('');

    const sendMessage = (message: string) => {
        const promise = Chat2EndpointPostPromise(chatbotState!, message);

        const newMessages = messagesState.data.messages;
        newMessages.push({
            role: 'user',
            content: message,
        });

        setMessagesState({
            status: 200,
            userState: {
                userModel: {
                    name: messagesState.userState.userModel.name,
                }
            },
            data: {
                chatbot: messagesState.data.chatbot,
                messages: newMessages,
            },
        });

        setSendingState(true);
        setMessageState('');

        promise.then((response) => {
            response.json().then((response: ChatResponse) => {
                setMessagesState(response);
                setSendingState(false);
            });
        });
    };

    const returnReverted = (messages: any[]) => {
        const reversedMessages = [...messages].reverse();

        return reversedMessages;
    };

    const [sendingState, setSendingState] = useState(false);

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3,
                justifyContent: chatbotState !== '' ? 'center' : 'flex-start',
                paddingTop: { xs: 1, md: 8, },
                width: '100%',
                p: 3,
                boxSizing: 'border-box',
                height: {
                    xs: 'calc(100vh - 130px)',
                    md: 'calc(100vh - 155px)'
                },
            }}
        >
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                }}
            >
                {chatbotsState.status === 200 && chatbotState === '' && chatbotsState.data.chatbots.length > 0 &&
                    <>
                        <Typography
                            variant="h4"
                        >
                            {translations.chatbots}
                        </Typography>
                        <Grid container spacing={2}>
                            {chatbotsState.data.chatbots.map((chatbot: ChatbotModel, index: number) => (
                                <Grid item xs={12} md={6} lg={4} key={index}>
                                    <Button
                                        key={index}
                                        onClick={() => setChatbotState(chatbot.id)}
                                        sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            color: 'white',
                                            paddingY: 1,
                                            borderRadius: 2,
                                            width: '100%',
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.dark,
                                            },
                                        }}
                                    >
                                        {chatbot.name}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                }
                {chatbotsState.status === 200 && chatbotState === '' && chatbotsState.data.chatbots.length === 0 &&
                    <Typography
                        variant="h4"
                    >
                        {translations.noChatbotForCourse}
                    </Typography>
                }
                {chatbotsState.status === 200 && chatbotState !== '' &&
                    <Box
                        sx={{
                            width: { xs: '100%', md: '100%' },
                            maxWidth: { xs: '100vw', md: '80vw' },
                            height: { xs: '100%', md: '100%' },
                            maxHeight: { xs: '100vh', md: '60vh' },
                            display: 'flex',
                            borderRadius: 4,
                            backgroundColor: 'white',
                            flexDirection: 'column',
                            zIndex: 4,
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingX: 2,
                                borderRadius: '16px 16px 0 0',
                                borderBottom: '3px solid rgba(255, 255, 255, 0.5)',
                            }}
                        >
                            <Typography
                                variant="h4"
                                color="white"
                            >
                                {messagesState.status === 200 && messagesState.data.chatbot.name}
                            </Typography>
                            <Box>
                            </Box>
                        </Box>
                        {messagesState.status === 200 &&
                            <Box
                                sx={{
                                    height: 'calc(100% - 50px)',
                                    width: '100%',
                                    backgroundColor: 'white',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                    overflowY: 'auto',
                                    scrollBehavior: 'smooth',
                                }}
                            >
                                {sendingState && (
                                    <Message
                                        message={{
                                            role: 'assistant',
                                            content: 'Thinking...',
                                        }}
                                        index={0}
                                        chatbotTitle={messagesState.data.chatbot.name}
                                        user={messagesState.userState?.userModel?.name}
                                    />
                                )}
                                {messagesState.status === 200 &&
                                    returnReverted(messagesState.data.messages).map((message, index) => (
                                        <Message
                                            key={index}
                                            message={message}
                                            index={index}
                                            chatbotTitle={messagesState.data.chatbot.name}
                                            user={messagesState.userState.userModel.name}
                                        />
                                    ))
                                }
                            </Box>
                        }
                        <Box
                            sx={{
                                height: 'auto',
                                display: 'flex',
                                position: 'relative',
                                p: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    position: 'relative',
                                    bottom: 0,
                                }}
                            >
                                <TextField
                                    sx={{
                                        width: '100%',
                                        outline: '1px solid rgba(0, 0, 0, 0.5)',
                                        borderRadius: 4,
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: 4,
                                                border: 0,
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'transparent',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'transparent',
                                            },
                                        },
                                        '.MuiInputBase-input': {
                                            width: '90%'
                                        },
                                    }}
                                    variant="outlined"
                                    value={messageState}
                                    onChange={(e) => setMessageState(e.target.value)}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter' || e.key === 'NumpadEnter' || e.key === 'Return') {
                                            if (!e.shiftKey) {
                                                sendMessage(messageState);
                                                e.preventDefault();
                                            }
                                        }
                                    }}
                                    multiline
                                    autoComplete="off"
                                />
                                <Button
                                    onClick={() => sendMessage(messageState)}
                                    variant="text"
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: 0,
                                        transform: 'translateY(-50%)',
                                    }}
                                >
                                    <Send />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                }
            </Container>
        </Box>
    );
}

export default Chatbots;
