import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FLAGS } from '../constants/flags';
import { theme } from '../generals/theme';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';
import FindResponse from '../responses/Find';
import { Find2EndpointGetHook } from '../routes/Find';
import { Join2EndpointPostPromise } from '../routes/Join';

interface Props {
    translations: any;
    updateCourses: () => void;
    setCourse: (course: string) => void;
}

interface Course {
    id: string;
    baseLanguage: string;
    targetLanguages: [string];
}

function CourseSwitcherPlain(props: Props) {
    const [findState, setFindState] = useState<FindResponse | EmptyResponseType>(EmptyResponse);

    const findResponse = Find2EndpointGetHook();

    useEffect(() => {
        if (findResponse.data) {
            setFindState(findResponse);
        }
    }, [findResponse.data]);

    const addCourse = (baseLanguage: string, targetLanguage: string) => {
        const promise = Join2EndpointPostPromise(baseLanguage, targetLanguage);

        promise.then((response) => {
            if (response.status === 200) {
                props.updateCourses();
                localStorage.setItem('course', baseLanguage + '__-__' + targetLanguage);
            }
        });
    };

    const ucFirst = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

    const flagExists = (language: string) => {
        const existingLanguages = FLAGS;

        return existingLanguages.includes(language.toLowerCase());
    };

    return (
        <>
            <Grid container spacing={2}>
                {findState.status === 200 && findState.data.languages.map((language: string) => (
                    <Grid item xs={6} sm={4} md={3}>
                        <Box
                            key={language}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingY: 2,
                                userSelect: 'none',
                                height: 200,
                                cursor: 'pointer',
                                borderRadius: 2,
                                backgroundColor: theme.palette.grey[200],
                                marginY: 1,
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            onClick={() => {
                                addCourse(findState.userState.userModel.language, language);
                            }}
                        >
                            {flagExists(language) &&
                                <img
                                    src={'/' + language + '.png'}
                                    alt={language}
                                    style={{
                                        width: 50,
                                        borderRadius: 5,
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        marginRight: 5,
                                    }}
                                />
                            }
                            <Typography
                                variant='body1'
                                component='p'
                                sx={{
                                    textAlign: 'center',
                                    paddingX: 1,
                                }}
                            >
                                {ucFirst(language)}
                            </Typography>
                        </Box>
                    </Grid>
                )
                )}
            </Grid>
            <Box>
                {findState.status === 200 && findState.data.alternativeCoursesBetter.map((course: Course) => (
                    <Grid container spacing={2}>
                        {course.targetLanguages.map((language: string) => (
                            <Grid item xs={6} sm={4} md={3}>
                                <Box
                                    key={language}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        paddingY: 2,
                                        userSelect: 'none',
                                        height: 200,
                                        cursor: 'pointer',
                                        borderRadius: 2,
                                        backgroundColor: theme.palette.grey[200],
                                        marginY: 1,
                                        transition: 'all 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                        },
                                    }}
                                    onClick={() => {
                                        addCourse(course.baseLanguage, language);
                                    }}
                                >
                                    {flagExists(language) &&
                                        <img
                                            src={'/' + language + '.png'}
                                            alt={language}
                                            style={{
                                                width: 50,
                                                borderRadius: 5,
                                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                                marginRight: 5,
                                            }}
                                        />
                                    }
                                    <Typography
                                        variant='body1'
                                        component='p'
                                        sx={{
                                            textAlign: 'center',
                                            paddingX: 1,
                                        }}
                                    >
                                        {ucFirst(language) + ' (' + ucFirst(course.baseLanguage) + ')'}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                ))}
            </Box>
        </>
    );
}

export default CourseSwitcherPlain;
