import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NewLogin from './generals/NewLogin';

interface Props {
    translations: any;
    stateState?: any;
}

interface AlternativeCourseObject {
    baseLanguage: string;
    targetLanguages: string[];
}

function JoinV2(props: Props) {
    const navigate = useNavigate();

    const [showLogin, setShowLogin] = useState<boolean>(false);

    const isLoggedIn = props.stateState?.status === 200 && props.stateState.userState.isLogin;

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => { isLoggedIn ? navigate('/home') : navigate('/join'); }}
                    sx={{
                        px: 8,
                        fontSize: '1.5rem',
                        marginTop: 2,
                    }}
                >
                    {isLoggedIn
                        ? props.translations.continue
                        : props.translations.getStarted}
                </Button>
                {!isLoggedIn &&
                    <Button
                        variant='text'
                        color='primary'
                        sx={{
                            marginTop: 2,
                        }}
                        onClick={() => setShowLogin(true)}
                    >
                        {props.translations.ialreadyhaveanaccount}
                    </Button>
                }
            </Box>
            <NewLogin
                open={showLogin}
                translations={props.translations}
                close={() => setShowLogin(false)}
            />
        </Box >
    );
}

export default JoinV2;
