import { Box, Typography } from '@mui/material';
import { theme } from './generals/theme';
import LearningPlanCard from './LearningPlanCard';
import LectionsResponse from './responses/Lections';

interface Props {
    lectionsState: LectionsResponse;
    todayLectionIndex: number;
    translations: any;
}

function LearningPlan(props: Props) {
    const getAllLectionsStartingNow = () => {
        const lections = props.lectionsState.data.lections;

        return lections.slice(props.todayLectionIndex - 1);
    };

    const getAllPriorLections = () => {
        const lections = props.lectionsState.data.lections;

        return lections.slice(0, props.todayLectionIndex - 1);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'calc(100vh - 144px)',
                width: '100%',
                p: 2,
            }}
        >
            <Typography
                variant='h4'
                sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                }}
            >
                {props.translations.learningPlan}
            </Typography>
            <Box
                id='learningPlan'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography
                    variant='h5'
                    sx={{
                        fontWeight: 'bold',
                        color: theme.palette.primary.main,
                        my: 1,
                    }}
                >
                    {props.translations.comingUp}
                </Typography>
                {props.lectionsState.status === 200 && getAllLectionsStartingNow().map((lection: any, index: number) => (
                    <LearningPlanCard
                        key={lection.id}
                        lectionIndex={index + props.todayLectionIndex}
                        lection={lection}
                        link={`/courses/${props.lectionsState.data.baseLanguage}/${props.lectionsState.data.targetLanguage}/${lection.id}/lesson`}
                        baseLanguage={props.lectionsState.data.baseLanguage}
                        targetLanguage={props.lectionsState.data.targetLanguage}
                    />
                ))}
                {props.lectionsState.status === 200 && getAllPriorLections().length > 0 &&
                    <Box
                        sx={{
                            borderTop: '1px solid ' + theme.palette.grey[300],
                            mt: 2,
                        }}
                    >
                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                color: theme.palette.primary.main,
                                my: 1,
                            }}
                        >
                            {props.translations.priorLections}
                        </Typography>
                        {props.lectionsState.status === 200 && getAllPriorLections().map((lection: any, index: number) => (
                            <LearningPlanCard
                                key={lection.id}
                                lectionIndex={index + 1}
                                lection={lection}
                                link={`/courses/${props.lectionsState.data.baseLanguage}/${props.lectionsState.data.targetLanguage}/${lection.id}/lesson`}
                                baseLanguage={props.lectionsState.data.baseLanguage}
                                targetLanguage={props.lectionsState.data.targetLanguage}
                            />
                        ))}
                    </Box>
                }
            </Box>
        </Box>
    );
}

export default LearningPlan;
