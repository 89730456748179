import { Add, VolumeUp } from '@mui/icons-material';
import { Box, Button, Container, FormControl, Grid, IconButton, TextField, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { appleButton } from '../constants/appleButton';
import { FLAGS } from '../constants/flags';
import LairnerModal from '../generals/LairnerModal';
import { TTS } from '../generals/speak';
import { theme } from '../generals/theme';
import ucFirst from '../generals/ucFirst';
import { MatchingWordModel } from '../models/MatchingWordModel';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';
import PhasesResponse from '../responses/Phases';
import { AddCustom2EndpointPostPromise } from '../routes/AddCustom';
import { Language2EndpointGetHook } from '../routes/Language';
import { Phases2EndpointGetHook, Phases2EndpointGetPromise } from '../routes/Phases';
import Vocabs from './Vocabs';

interface Props {
    baseLanguage?: string;
    targetLanguage?: string;
    setTab?: (tab: string) => void;
    train?: boolean;
    setTrain?: (train: boolean) => void;
}

function VocabsOverview(props: Props) {
    let { baseLanguage, targetLanguage } = useParams();

    if (baseLanguage === undefined) {
        baseLanguage = props.baseLanguage;
    }

    if (targetLanguage === undefined) {
        targetLanguage = props.targetLanguage;
    }

    const [showTraining, setShowTraining] = useState(props.train ?? false);

    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [translations, setTranslations] = useState({
        reviewYourVocab: '',
        youreAllDoneNoVocabToReviewRightNow: '',
        practiceYourVocabInOrderToMoveThemFromShortToLongTermMemory: '',
        doAnotherLessonToLearnSomeNewVocabs: '',
        noVocabs: '',
        iwascorrect: '',
        iwaswrong: '',
        back: '',
        start: '',
        total: '',
        phase1: '',
        phase2: '',
        phase3: '',
        phase4: '',
        phase5: '',
        phase6: '',
        due: '',
        allVocabs: '',
        reviewDueVocabs: '',
        phase0Tooltip: '',
        phase1Tooltip: '',
        phase2Tooltip: '',
        phase3Tooltip: '',
        phase4Tooltip: '',
        phase5Tooltip: '',
        phase6Tooltip: '',
        phases: '',
        search: '',
        addCustomVocab: '',
        transliteration: '',
        baseWord: '',
        targetWord: '',
    });

    const translationsResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));

    useEffect(() => {
        if (translationsResponse.data) {
            setTranslations(translationsResponse.data.translations);
        }
    }, [translationsResponse.data]);

    const [phasesState, setPhasesState] = useState<PhasesResponse | EmptyResponseType>(EmptyResponse);

    const phasesResponse = Phases2EndpointGetHook(baseLanguage ?? props.baseLanguage ?? '', targetLanguage ?? props.targetLanguage ?? '');

    useEffect(() => {
        if (phasesResponse.data) {
            setPhasesState(phasesResponse);
        }
    }, [phasesResponse.data]);

    const getCountPerPhase = (phase: string) => {
        if (phasesState.status === 200) {
            return phasesState.data.countPerPhase[phase] ?? 0;
        }

        return 0;
    };

    const total = () => {
        let total = 0;

        if (phasesState.status === 200) {
            Object.keys(phasesState.data.countPerPhase).forEach((phase) => {
                total += phasesState.data.countPerPhase[phase];
            });
        }

        return total;
    };

    const [searchState, setSearchState] = useState('');

    const [phasesList, setPhasesList] = useState<MatchingWordModel[]>([]);

    useEffect(() => {
        if (phasesState.status === 200) {
            setPhasesList(phasesState.data.allVocabs);
        }
    }, [phasesState.data]);

    useEffect(() => {
        if (searchState === '') {
            if (phasesState.status === 200) {
                setPhasesList(phasesState.data.allVocabs);
            }
        } else {
            setPhasesList(phasesState.data.allVocabs.filter((vocab: MatchingWordModel) => vocab.targetWord.toLowerCase().includes(searchState.toLowerCase()) || vocab.baseWord.toLowerCase().includes(searchState.toLowerCase())));
        }
    }, [searchState]);

    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [baseWord, setBaseWord] = useState('');
    const [targetWord, setTargetWord] = useState('');
    const [transliteration, setTransliteration] = useState('');

    const addCustomVocab = () => {
        const promise = AddCustom2EndpointPostPromise(baseWord, targetWord, transliteration, baseLanguage + '__-__' + targetLanguage);

        promise.then(() => {
            setBaseWord('');
            setTargetWord('');
            setTransliteration('');
            setAddDialogOpen(false);

            const phasesPromise = Phases2EndpointGetPromise(baseLanguage ?? '', targetLanguage ?? '');

            phasesPromise.then((response) => {
                setPhasesState(response);
            });
        });
    };

    return (
        <Box
            sx={{
                paddingTop: { xs: 1, md: 6 },
                minHeight: 'calc(100vh - 144px)',
            }}
        >
            {phasesState.status === 200 && !showTraining &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <Container
                        maxWidth='xl'
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            justifyContent: 'center',
                            my: 4,
                        }}
                    >
                        <Typography
                            variant='h4'
                            sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                marginTop: 2,
                            }}
                        >
                            {translations.reviewYourVocab}
                        </Typography>
                        <Typography
                            variant='h6'
                            sx={{
                                marginTop: 2,
                                textAlign: 'center',
                            }}
                        >
                            {phasesState.data.dueCount > 0
                                ? translations.practiceYourVocabInOrderToMoveThemFromShortToLongTermMemory
                                : translations.youreAllDoneNoVocabToReviewRightNow}
                        </Typography>
                        {phasesState.data.dueCount > 0 &&
                            <Button
                                variant='contained'
                                onClick={() => {
                                    setShowTraining(true);
                                    if (!isMobile) {
                                        window.history.pushState({}, '', '/home/vocab/train');
                                    } else {
                                        window.history.pushState({}, '', '/vocab/' + baseLanguage + '/' + targetLanguage + '/train');
                                    }
                                }}
                                sx={{
                                    marginTop: 2,
                                    ...appleButton,
                                }}
                            >
                                {translations.reviewYourVocab}
                            </Button>
                        }
                        {phasesState.data.dueCount === 0 &&
                            <Button
                                variant='contained'
                                onClick={() => {
                                    props.setTab && props.setTab('today');
                                }}
                                sx={{
                                    marginTop: 2,
                                    ...appleButton,
                                }}
                            >
                                {translations.doAnotherLessonToLearnSomeNewVocabs}
                            </Button>
                        }
                    </Container>
                    {phasesState.data.allVocabs.length > 0 &&
                        <Typography
                            variant='h6'
                            sx={{
                                fontWeight: 'bold',
                                marginTop: 6,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            color='text.primary'
                        >
                            {translations.allVocabs}
                            <IconButton
                                onClick={() => setAddDialogOpen(true)}
                                color='primary'
                            >
                                <Add />
                            </IconButton>
                        </Typography>
                    }
                    <LairnerModal
                        open={addDialogOpen}
                        close={() => setAddDialogOpen(false)}
                        title={translations.addCustomVocab}
                    >
                        <FormControl
                            fullWidth
                            sx={{
                                marginY: 2,
                            }}
                        >
                            <TextField
                                value={baseWord}
                                onChange={(e) => setBaseWord(e.target.value)}
                                label={translations.baseWord + ' (' + ucFirst(baseLanguage ?? props.baseLanguage ?? '') + ')*'}
                                variant='outlined'
                                fullWidth
                                sx={{
                                    marginBottom: 2,
                                }}
                            />
                            <TextField
                                value={targetWord}
                                onChange={(e) => setTargetWord(e.target.value)}
                                label={translations.targetWord + ' (' + ucFirst(targetLanguage ?? props.targetLanguage ?? '') + ')*'}
                                variant='outlined'
                                fullWidth
                                sx={{
                                    marginBottom: 2,
                                }}
                            />
                            <TextField
                                value={transliteration}
                                onChange={(e) => setTransliteration(e.target.value)}
                                label={translations.transliteration}
                                variant='outlined'
                                fullWidth
                                sx={{
                                    marginBottom: 2,
                                }}
                            />
                            <Button
                                variant='contained'
                                onClick={addCustomVocab}
                            >
                                {translations.addCustomVocab}
                            </Button>
                        </FormControl>
                    </LairnerModal>
                    <Container
                        maxWidth='xl'
                        sx={{
                            marginTop: 2,
                        }}
                    >
                        <TextField
                            value={searchState}
                            onChange={(e) => setSearchState(e.target.value)}
                            label={translations.search}
                            variant='outlined'
                            fullWidth
                            sx={{
                                marginBottom: 2,
                            }}
                        />
                        {phasesList.length > 0 &&
                            <Grid
                                container
                                spacing={1}
                                sx={{
                                    py: 2,
                                }}
                            >
                                {phasesList.map((vocab: MatchingWordModel) => (
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        key={vocab.id}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: theme.palette.grey[200],
                                                borderRadius: 2,
                                                width: '100%',
                                                padding: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}
                                        >
                                            {FLAGS.includes(targetLanguage!) &&
                                                <img
                                                    src={'/' + targetLanguage + '.png'}
                                                    style={{
                                                        width: 45,
                                                        borderRadius: 5,
                                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                                    }}
                                                />
                                            }
                                            <IconButton
                                                color='primary'
                                                onClick={
                                                    () => { TTS(vocab.targetWord, targetLanguage!); }
                                                }
                                                sx={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <VolumeUp
                                                />
                                            </IconButton>
                                            <Typography
                                                variant='body1'
                                                color='text.primary'
                                            >
                                                {vocab.targetWord}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    marginLeft: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 2,
                                                }}
                                            >
                                                <Typography
                                                    variant='body1'
                                                    color='text.primary'
                                                >
                                                    {vocab.baseWord}
                                                </Typography>
                                                {FLAGS.includes(baseLanguage!) &&
                                                    <img
                                                        src={'/' + baseLanguage + '.png'}
                                                        style={{
                                                            width: 45,
                                                            borderRadius: 5,
                                                            border: '1px solid rgba(0, 0, 0, 0.12)',
                                                            marginRight: 5,
                                                        }}
                                                    />
                                                }
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        }
                    </Container>
                </Box>
            }
            {phasesState.status === 200 && showTraining &&
                <Vocabs
                    speak={TTS}
                    baseLanguage={baseLanguage}
                    targetLanguage={targetLanguage}
                    setTrain={props.setTrain}
                />
            }
        </Box>
    );
}

export default VocabsOverview;

