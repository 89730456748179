import React from 'react';
import { Container, Typography } from '@mui/material';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import useTranslationState from './generals/useTranslationState';

function Imprint() {
    const translations = useTranslationState({
        imprint: '',
    });

    const imprintContent = `
**Company Name:**  
Alexander Bösing lairner GbR *(lairner is the brand name)*

**Represented by:**  
Tim Anthony Alexander (CEO) & Lukas Bösing  
*Each holding 50% ownership*

**Address:**  
Berliner Pl. 39  
48143 Münster  
Germany

**Contact Information:**  
Tel: +49 176 40170381  
Email: [info@lairner.app](mailto:info@lairner.app)

**Registration Details:**  
Currently being filed.

**Privacy Policy:**  
For our Privacy Policy, please visit [here](/privacy).`;

    return (
        <Container
            maxWidth="md"
            sx={{
                paddingTop: { xs: 2, md: 8 },
                minHeight: 'calc(100vh - 80px)',
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    fontWeight: 'bold',
                    marginBottom: 2,
                }}
            >
                {translations.imprint}
            </Typography>
            <ReactMarkdown
            >
                {imprintContent}
            </ReactMarkdown>
        </Container>
    );
}

export default Imprint;
