import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FLAGS } from './constants/flags';
import useIsMobile from './constants/useIsMobile';
import { theme } from './generals/theme';
import ucFirst from './generals/ucFirst';
import useTranslationState from './generals/useTranslationState';
import AnonResponse from './responses/Anon';
import EmptyResponse from './responses/EmptyResponse';
import EmptyResponseType from './responses/EmptyResponseType';
import { Anon2EndpointGetPromise, Anon2EndpointPostPromise } from './routes/Anon';

interface CourseByTargetLanguage {
    targetLanguage: string;
    hasMultipleBaseLanguages: boolean;
    baseLanguages: string[];
}

function Register() {
    const isMobile = useIsMobile();
    const navigate = useNavigate();

    const translations = useTranslationState({
        back: '',
        iSpeak: '',
        iWantToLearn: '',
    });

    const [anon, setAnon] = useState<AnonResponse | EmptyResponseType>(EmptyResponse);

    const flagExists = (language: string) => {
        const existingLanguages = FLAGS;

        return existingLanguages.includes(language.toLowerCase());
    };

    const [loading, setLoading] = useState<string | null>(null);

    const currentLanguage = localStorage.getItem('languageSet') || 'english';

    const joinCourse = (baseLanguage: string, course: string) => {
        setLoading(course);

        const promise = Anon2EndpointPostPromise(
            baseLanguage,
            course,
        );

        promise.then((response) => {
            response.json().then((data: AnonResponse) => {
                setLoading(null);
                window.location.href = data.data.firstLection;
            });
        });
    };

    useEffect(() => {
        const promise = Anon2EndpointGetPromise(currentLanguage, '');

        promise.then((response: any) => {
            setAnon(response);
        });
    }, [currentLanguage]);

    const [selectedTargetLanguage, setSelectedTargetLanguage] = useState<string | null>(null);
    const [possibleBaseLanguages, setPossibleBaseLanguages] = useState<string[]>([]);

    const selectTargetLanguage = (course: CourseByTargetLanguage) => {
        if (!course.hasMultipleBaseLanguages) {
            joinCourse(course.baseLanguages[0], course.targetLanguage);

            return;
        }

        setSelectedTargetLanguage(course.targetLanguage);
        setPossibleBaseLanguages(course.baseLanguages);
    };

    const cardStyle = {
        backgroundColor: theme.palette.grey[200],
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2,
        height: 130,
        cursor: 'pointer',
        transition: 'all 0.3s',
        '&:hover': {
            transform: 'scale(1.05)',
            backgroundColor: theme.palette.grey[300],
        },
    };

    return (
        <Box
            sx={{
                width: '100%',
                py: 4,
            }}
        >
            {!isMobile &&
                <Box
                    sx={{
                        width: '100%',
                        height: 64,
                    }}
                >
                </Box>
            }
            {anon.status === 200 &&
                <Container
                    sx={{
                        minHeight: 'calc(100vh - 64px)',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {selectedTargetLanguage !== null &&
                        <Box
                            sx={{
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setSelectedTargetLanguage(null);
                                    setPossibleBaseLanguages([]);
                                }}
                            >
                                {translations.back}
                            </Button>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    variant='h4'
                                    sx={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        mt: 2,
                                    }}
                                >
                                    {translations.iSpeak}
                                </Typography>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        marginTop: 4,
                                    }}
                                >
                                    {possibleBaseLanguages.map((baseLanguage: string) => (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={3}
                                            key={baseLanguage}
                                        >
                                            <Box
                                                sx={{
                                                    ...cardStyle,
                                                }}
                                                onClick={() => {
                                                    joinCourse(baseLanguage, selectedTargetLanguage);
                                                }}
                                            >
                                                {flagExists(baseLanguage) &&
                                                    <img
                                                        src={'/' + baseLanguage + '.png'}
                                                        alt={baseLanguage}
                                                        style={{
                                                            width: 100,
                                                            objectFit: 'cover',
                                                            borderRadius: 8,
                                                            border: '0.1px solid ' + theme.palette.grey[300],
                                                        }}
                                                    />
                                                }
                                                <Typography
                                                    variant='h6'
                                                    sx={{
                                                        textAlign: 'center',
                                                        mt: 1,
                                                    }}
                                                >
                                                    {ucFirst(baseLanguage)}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>
                    }
                    {selectedTargetLanguage === null &&
                        <Box>
                            <Typography
                                variant='h4'
                                sx={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    mt: 2,
                                }}
                            >
                                {translations.iWantToLearn}
                            </Typography>
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    marginTop: 4,
                                }}
                            >
                                {anon.data.coursesByTargetLanguage.map((course: CourseByTargetLanguage) => (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        key={course.targetLanguage}
                                    >
                                        <Box
                                            sx={{
                                                ...cardStyle,
                                            }}
                                            onClick={() => {
                                                selectTargetLanguage(course);
                                            }}
                                        >
                                            {flagExists(course.targetLanguage) &&
                                                <img
                                                    src={'/' + course.targetLanguage + '.png'}
                                                    alt={course.targetLanguage}
                                                    style={{
                                                        width: 100,
                                                        objectFit: 'cover',
                                                        borderRadius: 8,
                                                        border: '0.1px solid ' + theme.palette.grey[300],
                                                    }}
                                                />
                                            }
                                            <Typography
                                                variant='h6'
                                                sx={{
                                                    textAlign: 'center',
                                                    mt: 1,
                                                }}
                                            >
                                                {ucFirst(course.targetLanguage)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    }
                </Container>
            }
        </Box>
    );
}

export default Register;
