import { Box, LinearProgress, Typography } from '@mui/material';
import { theme } from '../generals/theme';
import { getHost } from '../modules/api/client';

interface Props {
    baseLanguage: string;
    targetLanguage: string;
    todayLection: { id: string; lection: string; completion: number; parts: number; learnText: string };
    todayLectionIndex: number;
    navigate: (url: string) => void;
    canDoNormalLesson: () => boolean;
    translations: any;
    todayLessonState: { data: { image: string; isGrammar: boolean, isUsable: boolean } };
    shouldDoVocab: () => boolean;
}

function LessonCard(props: Props) {
    const handleClick = () => {
        if (props.todayLessonState.data.isUsable === false) {
            return;
        }

        if (props.canDoNormalLesson()) {
            props.navigate(`/courses/${props.baseLanguage}/${props.targetLanguage}/${props.todayLection.id}/lesson`);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                backgroundColor: theme.palette.primary.main + (props.shouldDoVocab() ? 'CC' : ''),
                transition: 'background-color 0.3s',
                '&:hover': {
                    backgroundColor: props.canDoNormalLesson()
                        ? theme.palette.primary.dark
                        : theme.palette.primary.main,
                },
                p: 2,
                height: 300,
                borderRadius: 2,
                cursor: props.canDoNormalLesson() ? 'pointer' : 'not-allowed',
                userSelect: 'none',
                position: 'relative',
            }}
            onClick={handleClick}
        >
            {!props.shouldDoVocab() &&
                <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                    <img
                        src={`${getHost()}/image.php?file=${props.todayLessonState.data.image}`}
                        alt={props.todayLection.lection}
                        style={{
                            width: 75,
                            height: 75,
                            borderRadius: 12,
                            filter: 'brightness(0.9) blur(0.5px)',
                        }}
                    />
                </Box>
            }
            <Typography variant="caption" sx={{ color: 'primary.contrastText' }}>
                {props.translations.lectionX.replace('[X]', props.todayLectionIndex.toString())}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.contrastText', mt: 1 }}>
                {props.todayLection.lection}
            </Typography>
            <LinearProgress
                variant="determinate"
                color="secondary"
                value={(props.todayLection.completion / props.todayLection.parts) * 100}
                sx={{
                    backgroundColor: 'primary.contrastText',
                    mt: 'auto',
                    height: 10,
                    borderRadius: 5,
                }}
            />
        </Box>
    );
}

export default LessonCard;
