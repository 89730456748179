import { Box, Typography } from '@mui/material';

interface Props {
    baseLanguage: string;
    targetLanguage: string;
    vocabCount: number;
    isMobile: boolean;
    navigate: (url: string) => void;
    translations: any;
    canDoNormalLesson: () => boolean;
    setTab: (tab: string) => void;
    setTrain: (train: boolean) => void;
}

function LaterVocabCard(props: Props) {
    const handleClick = () => {
        if (props.isMobile) {
            props.navigate(`/vocab/${props.baseLanguage}/${props.targetLanguage}/train`);
        } else {
            props.setTab('vocab');
            props.setTrain(true);
            window.history.pushState({}, '', '/home/vocab/train');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                backgroundColor: 'primary.main',
                transition: 'background-color 0.3s',
                '&:hover': {
                    backgroundColor: props.canDoNormalLesson() ? 'primary.dark' : 'primary.main',
                },
                p: 2,
                height: 300,
                borderRadius: 2,
                cursor: props.canDoNormalLesson() ? 'pointer' : 'not-allowed',
                userSelect: 'none',
                position: 'relative',
            }}
            onClick={handleClick}
        >
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.contrastText', mt: 1 }}>
                {props.translations.doVocab}
            </Typography>
            <Typography variant="caption" sx={{ fontWeight: 'bold', color: 'primary.contrastText', mt: 1 }}>
                {props.translations.youHaveXVocabsToDo.replace('[X]', props.vocabCount.toString())}
            </Typography>
            <Box sx={{ display: 'flex', marginTop: 'auto', alignItems: 'flex-end' }}>
                <Typography variant="body1" sx={{ color: 'primary.contrastText' }}>
                    {props.translations.youHaveSomeOpenVocabWeRecommendYouToDoThemBeforeContinuingWithTheNextUnit}
                </Typography>
            </Box>
        </Box>
    );
}

export default LaterVocabCard;
