import React, { useEffect, useState } from 'react';
import { MatchingWord } from '../../models/MatchingWord';
import Grid from '@mui/material/Grid';
import { shuffleArray } from '../../generals/shuffle';
import { theme } from '../../generals/theme';
import { Box, Typography } from '@mui/material';
import { TTS } from '../../generals/speak';
import SelectChip from './SelectChip';
import { TRANSLITERATIONS } from '../../constants/transliterations';
import AnswerFeedbackV2 from '../AnswerFeedbackV2';

interface Props {
    currentQuestion: MatchingWord[];
    next: () => void;
    translations: any;
    baseLanguage: string;
    targetLanguage: string;
    contentPosition: any;
}

interface SelectedChip {
    type: 'baseWord' | 'targetWord';
    word: string;
    id: string;
}

function MatchingQuestion(props: Props) {
    const [selectedChip, setSelectedChip] = useState<SelectedChip | null>(null);
    const [matchedPairs, setMatchedPairs] = useState<string[]>([]);

    useEffect(() => {
        setLeftRowState(randomizeWords());
        setRightRowState(randomizeWords());
        setSelectedChip(null);
        setMatchedPairs([]);
    }, [props.currentQuestion]);

    const handleChipClick = (type: 'baseWord' | 'targetWord', id: string, word: string) => {
        if (selectedChip && selectedChip.type !== type) {
            const match = props.currentQuestion.find(word =>
                (word.id === id && selectedChip.id === word.id) ||
                (word.id === selectedChip.id && id === word.id)
            );

            if (match) {
                const newMatchedPairs = [...matchedPairs, match.id];
                setMatchedPairs(newMatchedPairs);
                setSelectedChip(null);
                if (newMatchedPairs.length === props.currentQuestion.length) {
                    const correctAudio = new Audio('/correct_piano.mp3');
                    correctAudio.play();

                }
            } else {
                setSelectedChip(null);
                const incorrectAudio = new Audio('/incorrect_piano.mp3');
                incorrectAudio.play();
            }

            if (type === 'baseWord') {
                TTS(word, props.targetLanguage);
            }
        } else {
            setSelectedChip({ type, id, word });
            if (type === 'baseWord') {
                TTS(word, props.targetLanguage);
            }
        }

        if (word === selectedChip?.word) {
            setSelectedChip(null);
        }
    };

    const randomizeWords = () => shuffleArray(props.currentQuestion);

    const [leftRowState, setLeftRowState] = useState(randomizeWords());
    const [rightRowState, setRightRowState] = useState(randomizeWords());

    const getBorderColor = (word: MatchingWord, wordToCompare: string) => {
        if (selectedChip?.word === wordToCompare) {
            return theme.palette.success.main;
        }

        if (matchedPairs.includes(word.id)) {
            return theme.palette.success.main;
        }

        return theme.palette.primary.main;
    };

    const getBackgroundColor = (word: MatchingWord, wordToCompare: string) => {
        if (selectedChip?.word === wordToCompare) {
            return 'rgba(0, 200, 200, 0.3)';
        }

        if (matchedPairs.includes(word.id)) {
            return 'rgba(0, 255, 0, 0.2)';
        }

        return 'transparent';
    };

    const convertNumberToLetter = (number: number) => String.fromCharCode(97 + number);

    return (
        <Box
            sx={props.contentPosition}
        >
            <Grid
                container
                spacing={6}
                sx={{
                    flexGrow: 1,
                    marginTop: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        {props.translations.matching}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    {leftRowState.map((word, index) => (
                        <SelectChip
                            id={word.id}
                            text={word.targetWord}
                            onClick={() => handleChipClick('targetWord', word.id, word.targetWord)}
                            disabled={matchedPairs.includes(word.id)}
                            sx={{
                                border: '1px solid ' + getBorderColor(word, word.targetWord),
                                backgroundColor: getBackgroundColor(word, word.targetWord),
                                marginTop: 1,
                                transition: 'margin 0.5s',
                                marginLeft: matchedPairs.includes(word.id) ? 2 : 1,
                                width: '100%',
                                height: props.targetLanguage === 'chinese' || props.targetLanguage === 'cantonese' || TRANSLITERATIONS.includes(props.targetLanguage) ? 80 : 'auto',
                            }}
                            hotKey={(index + 1).toString()}
                            isMatching
                            draggable={false}
                            language={props.baseLanguage}
                        />
                    ))}
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    {rightRowState.map((word, index) => (
                        <SelectChip
                            id={word.id}
                            text={word.baseWord}
                            onClick={() => handleChipClick('baseWord', word.id, word.baseWord)}
                            disabled={matchedPairs.includes(word.id)}
                            hotKey={convertNumberToLetter(index)}
                            sx={{
                                border: '1px solid ' + getBorderColor(word, word.baseWord),
                                backgroundColor: getBackgroundColor(word, word.baseWord),
                                marginTop: 1,
                                transition: 'margin 0.5s',
                                marginLeft: matchedPairs.includes(word.id) ? -2 : -1,
                                width: '100%',
                                height: props.targetLanguage === 'chinese' || props.targetLanguage === 'cantonese' || TRANSLITERATIONS.includes(props.targetLanguage) ? 80 : 'auto',
                            }}
                            isMatching
                            draggable={false}
                            showPinYin={props.targetLanguage === 'chinese' || props.targetLanguage === 'cantonese'}
                            language={props.targetLanguage}
                        />
                    ))}
                </Grid>
            </Grid>
            <AnswerFeedbackV2
                message={props.translations.allCorrect}
                severity="success"
                correctAnswer="coooorrrreeeect"
                open={matchedPairs.length === props.currentQuestion.length}
                next={props.next}
                translations={props.translations}
                speak={TTS}
            />
        </Box>
    );
}

export default MatchingQuestion;
