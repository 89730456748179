import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { State2EndpointGetHook } from '../routes/State';
import useEndpoint from './useEndpoint';

interface Props {
    children?: React.ReactNode;
    redirectIfAnonymous?: boolean;
}

function LoginCheck(props: Props) {
    const navigate = useNavigate();

    const [state] = useEndpoint<any>(State2EndpointGetHook());

    useEffect(() => {
        if (state.status === 200) {
            if (state.userState.isLogin === false && props.redirectIfAnonymous !== false) {
                navigate('/');
            }
        }
    }, [state]);

    return (
        <>
            {state.status === 200 && state.userState.isLogin === true && props.children}
        </>
    );
}

export default LoginCheck;
