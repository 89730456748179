import { Container, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminTable from './admin/AdminTable';
import EmptyResponse from './responses/EmptyResponse';
import EmptyResponseType from './responses/EmptyResponseType';
import ListTablesResponse from './responses/ListTables';
import { ListTables2EndpointGetHook } from './routes/ListTables';

function Admin() {
    const { table, page, perPage } = useParams<{ table?: string, page?: string, perPage?: string }>();

    const [tablesState, setTablesState] = useState<ListTablesResponse | EmptyResponseType>(EmptyResponse);

    const tablesResponse = ListTables2EndpointGetHook();

    useEffect(() => {
        if (tablesResponse.data) {
            setTablesState(tablesResponse);
        }
    }, [tablesResponse]);

    const navigate = useNavigate();

    return (
        <Container
            maxWidth="xl"
            sx={{
                paddingTop: { xs: 1, md: 8, },
            }}
        >
            {tablesState.status === 200 && table === undefined &&
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Table
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {tablesState.data?.tables.map((table: string) => (
                        <TableRow
                            key={table}
                            onClick={() => navigate(`/admin/table/${table}`)}
                            sx={{
                                cursor: 'pointer',
                            }}
                        >
                            <TableCell >
                                {table}
                            </TableCell>
                        </TableRow>
                    ))}
                </Table>
            }
            {tablesState.status === 200 && table !== undefined &&
                <AdminTable
                    table={table!}
                    page={page ?? '1'}
                    perPage={perPage ?? '20'}
                />
            }
        </Container>
    );
}

export default Admin;
