import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise } from '../modules/api/client';

export function Check2EndpointGetHook(
    param_question: string, 
    param_answer: string, 
    param_lection: string, 
    param_isReverse: string, 
) {
    return GetWithHook(routes.Check, {
        question: param_question, 
        answer: param_answer, 
        lection: param_lection, 
        isReverse: param_isReverse, 
    });
}

export function Check2EndpointGetPromise(
    param_question: string, 
    param_answer: string, 
    param_lection: string, 
    param_isReverse: string, 
) {
    return GetWithPromise(routes.Check, {
        question: param_question, 
        answer: param_answer, 
        lection: param_lection, 
        isReverse: param_isReverse, 
    });
}

