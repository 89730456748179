import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function EditUser2EndpointGetHook(
    param_name: string, 
    param_oldPassword: string, 
    param_password: string, 
    param_description: string, 
) {
    return GetWithHook(routes.EditUser, {
        name: param_name, 
        oldPassword: param_oldPassword, 
        password: param_password, 
        description: param_description, 
    });
}

export function EditUser2EndpointGetPromise(
    param_name: string, 
    param_oldPassword: string, 
    param_password: string, 
    param_description: string, 
) {
    return GetWithPromise(routes.EditUser, {
        name: param_name, 
        oldPassword: param_oldPassword, 
        password: param_password, 
        description: param_description, 
    });
}

export function EditUser2EndpointPostPromise(
    param_name: string, 
    param_oldPassword: string, 
    param_password: string, 
    param_description: string, 
) {
    return PostWithPromise(routes.EditUserPost, {
        name: param_name, 
        oldPassword: param_oldPassword, 
        password: param_password, 
        description: param_description, 
    });
}

