import { Box, Button, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import EmptyResponse from '../responses/EmptyResponse';
import EmptyResponseType from '../responses/EmptyResponseType';
import SelectTableResponse from '../responses/SelectTable';
import { SelectTable2EndpointGetHook, SelectTable2EndpointGetPromise } from '../routes/SelectTable';

interface Props {
    table: string;
    page: string;
    perPage: string;
}

function AdminTable(props: Props) {
    const [page, setPage] = useState<number>(parseInt(props.page));
    const [perPage, setPerPage] = useState<number>(parseInt(props.perPage));

    const [selectTableState, setSelectTableState] = useState<SelectTableResponse | EmptyResponseType>(EmptyResponse);

    const selectTableResponse = SelectTable2EndpointGetHook(props.table, page.toString(), perPage.toString());

    useEffect(() => {
        if (selectTableResponse.data) {
            setSelectTableState(selectTableResponse);
        }
    }, [selectTableResponse]);

    const nextPage = () => {
        const nextPage = page + 1;

        setPage(nextPage);

        const promise = SelectTable2EndpointGetPromise(props.table, nextPage.toString(), perPage.toString());

        promise.then((response: SelectTableResponse) => {
            setSelectTableState(response);
        });
    };

    const previousPage = () => {
        const previousPage = page - 1;

        setPage(previousPage);

        const promise = SelectTable2EndpointGetPromise(props.table, previousPage.toString(), perPage.toString());

        promise.then((response: SelectTableResponse) => {
            setSelectTableState(response);
        });
    };

    const checkPreviousPage = () => {
        if (page - 1 === 0) {
            return false;
        }

        return true;
    };

    return (
        <>
            {selectTableState.status === 200 &&
                <Box
                    sx={{
                        width: '100%',
                        overflowX: 'auto',
                    }}
                >
                    <Table
                        size="small"
                    >
                        <TableHead>
                            <TableRow>
                                {selectTableState.data?.columns.map((column: string) => (
                                    <TableCell key={column}>
                                        {column}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {selectTableState.data?.data.map((row: any) => (
                            <TableRow
                                key={row[0]}
                            >
                                {row.map((column: any) => (
                                    <TableCell key={column}>
                                        {column}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </Table>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={nextPage}
                    >
                        Next
                    </Button>
                    {checkPreviousPage() &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={previousPage}
                        >
                            Previous
                        </Button>
                    }
                </Box>
            }
        </>
    );
}

export default AdminTable;
