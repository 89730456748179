import { Alert, Button, FormControl, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Signup2EndpointPostPromise } from '../routes/Signup';
import { Verify2EndpointPostPromise } from '../routes/Verify';
import LairnerModal from './LairnerModal';

interface Props {
    currentLanguageState: string;
    translations: any;
    askForToken: boolean;
    text?: string;
    email: string;
}

function NewSignup(props: Props) {
    const [openSignup, setOpenSignup] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState(props.email);
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [askForToken, setAskForToken] = useState(props.askForToken);
    const [loading, setLoading] = useState(false);

    const signup = () => {
        setLoading(true);
        const promise = Signup2EndpointPostPromise(name, email, password, '1');

        promise.then((response) => {
            if (response.status === 200) {
                setAskForToken(true);
            }
            setLoading(false);
        });
    };

    const verifyToken = () => {
        setLoading(true);
        const promise = Verify2EndpointPostPromise(email, token, '');

        promise.then((response) => {
            if (response.status === 200) {
                setOpenSignup(false);
                window.location.reload();
            }
            setLoading(false);
        });
    };

    return (
        <LairnerModal
            open={openSignup}
            title={props.translations.signup}
            close={() => setOpenSignup(false)}
        >
            <Typography variant='subtitle1' gutterBottom>
                {props.text ? props.text : props.translations.createAnAccountInOrderToNotLooseYourProgress}
            </Typography>
            {!askForToken &&
                <FormControl fullWidth>
                    <TextField
                        label={props.translations.name}
                        variant='outlined'
                        fullWidth
                        sx={{
                            marginBottom: 2,
                        }}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </FormControl>
            }
            {!askForToken &&
                <>
                    <FormControl fullWidth>
                        <TextField
                            label={props.translations.email}
                            variant='outlined'
                            fullWidth
                            sx={{
                                marginBottom: 2,
                            }}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </FormControl>
                    {email !== '' && (!email.includes('@') || /* check if email contains . after @ */ email.split('@')[1].split('.').length < 2) &&
                        <Alert
                            severity='error'
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            {props.translations.emailInvalid}
                        </Alert>
                    }
                    <FormControl fullWidth>
                        <TextField
                            label={props.translations.password}
                            variant='outlined'
                            fullWidth
                            sx={{
                                marginBottom: 2,
                            }}
                            type='password'
                            autoComplete='new-password'
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                    </FormControl>
                    {password.length < 8 && password !== '' &&
                        <Alert
                            severity='error'
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            {props.translations.passwordLength}
                        </Alert>
                    }
                </>
            }
            {askForToken &&
                <FormControl fullWidth>
                    <TextField
                        label={props.translations.onetimepin}
                        variant='outlined'
                        fullWidth
                        sx={{
                            marginBottom: 2,
                        }}
                        value={token}
                        onChange={(event) => setToken(event.target.value)}
                    />
                </FormControl>
            }
            <FormControl fullWidth>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={askForToken ? verifyToken : signup}
                    disabled={loading || (askForToken ? token === '' : name === '' || email === '')}
                >
                    {props.translations.createAccount}
                </Button>
            </FormControl>
            {askForToken && props.email !== '' &&
                <FormControl fullWidth>
                    <Button
                        variant='text'
                        color='primary'
                        onClick={signup}
                        sx={{
                            marginTop: 2,
                        }}
                    >
                        {props.translations.resendToken}
                    </Button>
                </FormControl>
            }
            <FormControl fullWidth>
                <Button
                    variant='text'
                    color='primary'
                    onClick={() => setOpenSignup(false)}
                    sx={{
                        marginTop: 2,
                    }}
                >
                    {props.translations.later}
                </Button>
            </FormControl>
        </LairnerModal>

    );
}

export default NewSignup;
