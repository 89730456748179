import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Error from './Error';
import { ThemeProvider } from '@mui/material';
import { theme } from './generals/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import Website from './generals/Website';
import Home from './Home';
import Course from './courses/Course';
import VocabsOverview from './vocabs/VocabsOverview';
import Admin from './Admin';
import Profile from './Profile';
import Lection from './courses/Lection/Lection';
import { TTS } from './generals/speak';
import Alphabet from './courses/Alphabet';
import Chatbots from './courses/Lection/Chatbots';
import Intro from './Intro';
import Leaderboard from './Leaderboard';
import { gettheme } from './generals/gettheme';
import AnonymousHomepage from './AnonymousHomepage';
import Imprint from './Imprint';
import PrivacyPolicy from './Privacy';
import Footer from './Footer';
import LoginCheck from './generals/LoginCheck';
import Register from './Register';
import AntiLoginCheck from './generals/AntiLoginCheck';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <Website>
                <AnonymousHomepage />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/join',
        element: (
            <Website>
                <AntiLoginCheck>
                    <Register />
                </AntiLoginCheck>
                <Footer />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/home',
        element: (
            <Website>
                <LoginCheck>
                    <Home />
                </LoginCheck>
                <Footer />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/home/:section',
        element: (
            <Website>
                <LoginCheck>
                    <Home />
                </LoginCheck>
                <Footer />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/home/:section/train',
        element: (
            <Website>
                <LoginCheck>
                    <Home
                        train={true}
                    />
                </LoginCheck>
                <Footer />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/courses/:baseLanguage/:targetLanguage/:lection',
        element: (
            <Website>
                <LoginCheck>
                    <Course />
                </LoginCheck>
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/courses/:baseLanguage/:targetLanguage/intro/intro',
        element: (
            <Website noMenu={true}>
                <Intro />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/courses/:baseLanguage/:targetLanguage/:lection/lesson',
        element: (
            <Website noMenu={true}>
                <LoginCheck>
                    <Lection
                        speak={TTS}
                    />
                </LoginCheck>
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/courses/:baseLanguage/:targetLanguage/intro/intro/nomenu',
        element: (
            <Website noMenu={true}>
                <Intro
                    noMenu={true}
                />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/courses/:baseLanguage/:targetLanguage/:lection/lesson/nomenu',
        element: (
            <Website noMenu={true}>
                <LoginCheck>
                    <Lection
                        speak={TTS}
                        noMenu={true}
                    />
                </LoginCheck>
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/vocab/:baseLanguage/:targetLanguage/train',
        element: (
            <Website>
                <LoginCheck>
                    <VocabsOverview
                        train={true}
                    />
                </LoginCheck>
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/vocab/:baseLanguage/:targetLanguage',
        element: (
            <Website>
                <LoginCheck>
                    <VocabsOverview />
                </LoginCheck>
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/alphabet',
        element: (
            <Website>
                <LoginCheck>
                    <Alphabet />
                </LoginCheck>
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/chatbots',
        element: (
            <Website>
                <LoginCheck>
                    <Chatbots />
                </LoginCheck>
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/admin/overview',
        element: (
            <Website>
                <LoginCheck>
                    <Admin />
                </LoginCheck>
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/admin/table/:table',
        element: (
            <Website>
                <Admin />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/admin/table/:table/:page',
        element: (
            <Website>
                <Admin />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/admin/table/:table/:page/:perPage',
        element: (
            <Website>
                <Admin />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/profile',
        element: (
            <Website>
                <LoginCheck>
                    <Profile />
                    <Footer />
                </LoginCheck>
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/leaderboard',
        element: (
            <Website>
                <LoginCheck>
                    <Leaderboard />
                </LoginCheck>
                <Footer />
            </Website>
        ),
        errorElement: <Error />,
    },
    // /imprint and /privacy
    {
        path: '/imprint',
        element: (
            <Website>
                <Imprint />
                <Footer />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '/privacy',
        element: (
            <Website>
                <PrivacyPolicy />
                <Footer />
            </Website>
        ),
        errorElement: <Error />,
    },
    {
        path: '*',
        element: (
            <Website>
                <AnonymousHomepage />
            </Website>
        ),
        errorElement: <Error />,
    }
]);

document.getElementsByTagName('html')[0].style.backgroundColor = gettheme()
    ? '#000000'
    : '#ffffff';

root.render(
    <ThemeProvider
        theme={theme}
    >
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="de"
        >
            <RouterProvider
                router={router}
            />
        </LocalizationProvider>
    </ThemeProvider>
);
