// Declare that ReactNativeWebView exists on window
declare global {
    interface Window {
        ReactNativeWebView: {
            postMessage: (message: string) => void;
        };
        // Also for swift webkit
        webkit: {
            messageHandlers: {
                close: {
                    postMessage: (message: string) => void;
                };
                vibrate: {
                    postMessage: (message: string) => void;
                };
                vibrateError: {
                    postMessage: (message: string) => void;
                };
                vibrateSuccess: {
                    postMessage: (message: string) => void;
                };
            };
        };
    }
}

export const sendVibration = (
    heaviness: 'simple' | 'success' | 'error' = 'simple',
) => {
    // window.webkit?.messageHandlers?.vibrate?.postMessage('Vibrate requested from JS');

    switch (heaviness) {
        case 'simple':
            window.ReactNativeWebView?.postMessage('vibrate');
            window.webkit?.messageHandlers?.vibrate?.postMessage('vibrate');
            break;
        case 'success':
            window.ReactNativeWebView?.postMessage('vibrateSuccess');
            window.webkit?.messageHandlers?.vibrateSuccess?.postMessage('vibrateSuccess');
            break;
        case 'error':
            window.ReactNativeWebView?.postMessage('vibrateError');
            window.webkit?.messageHandlers?.vibrateError?.postMessage('vibrateError');
            break;
    }
};
