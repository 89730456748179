import { State2EndpointGetHook } from '../routes/State';
import useEndpoint from './useEndpoint';

interface Props {
    children?: React.ReactNode;
}

function AntiLoginCheck(props: Props) {
    const [state] = useEndpoint<any>(State2EndpointGetHook());

    return (
        <>
            {state.status === 200 && state.userState.isLogin === false && props.children}
        </>
    );
}

export default AntiLoginCheck;


