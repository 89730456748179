import { Alert, Button, FormControl, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Login2EndpointPostPromise } from '../routes/Login';
import { Verify2EndpointPostPromise } from '../routes/Verify';
import LairnerModal from './LairnerModal';

interface Props {
    translations: any;
    open: boolean;
    close: () => void;
}

function NewLogin(props: Props) {
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [askForToken, setAskForToken] = useState(false);
    const [password, setPassword] = useState('');

    const [wrongCredentials, setWrongCredentials] = useState(false);

    const login = () => {
        const promise = Login2EndpointPostPromise(email);
        setWrongCredentials(false);

        promise.then((response) => {
            if (response.status === 200) {
                response.json().then((response: any) => {
                    if (response.data.loginWithToken) {
                        setAskForToken(true);
                    } else {
                        verifyToken();
                    }
                });
            } else {
                setWrongCredentials(true);
            }
        });
    };

    const verifyToken = () => {
        const promise = Verify2EndpointPostPromise(email, token, password);

        promise.then((response) => {
            if (response.status === 200) {
                props.close();
                window.location.reload();
            } else if (response.status === 409) {
                setWrongCredentials(true);
            }
        });
    };

    return (
        <LairnerModal
            open={props.open}
            close={props.close}
            title={props.translations.login}
        >
            <Typography variant='subtitle1' gutterBottom>
                {props.translations.createAnAccountInOrderToNotLooseYourProgress}
            </Typography>
            {!askForToken &&
                <>
                    <FormControl fullWidth>
                        <TextField
                            label={props.translations.email}
                            variant='outlined'
                            fullWidth
                            sx={{
                                marginBottom: 2,
                            }}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    login();
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label={props.translations.password}
                            variant='outlined'
                            type='password'
                            fullWidth
                            id='password'
                            autoComplete='current-password'
                            sx={{
                                marginBottom: 2,
                            }}
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    login();
                                }
                            }}
                        />
                    </FormControl>
                </>
            }
            {askForToken &&
                <FormControl fullWidth>
                    <TextField
                        label={props.translations.onetimepin}
                        variant='outlined'
                        fullWidth
                        sx={{
                            marginBottom: 2,
                        }}
                        value={token}
                        onChange={(event) => setToken(event.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                verifyToken();
                            }
                        }}
                    />
                </FormControl>
            }
            {wrongCredentials &&
                <Alert
                    severity='error'
                    sx={{
                        marginBottom: 2,
                    }}
                >
                    {props.translations.wrongCredentials}
                </Alert>
            }
            <FormControl fullWidth>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={askForToken ? verifyToken : login}
                >
                    {props.translations.login}
                </Button>
            </FormControl>
            {askForToken && email !== '' &&
                <FormControl fullWidth>
                    <Button
                        variant='text'
                        color='primary'
                        onClick={login}
                        sx={{
                            marginTop: 2,
                        }}
                    >
                        {props.translations.resendToken}
                    </Button>
                </FormControl>
            }
        </LairnerModal>
    );
}

export default NewLogin;

