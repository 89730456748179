import { Box, Grid, Typography } from '@mui/material';

interface Props {
    img: string;
    title: string;
    description: string;
}

function HomeFeature(props: Props) {
    return (
        <Grid
            item
            md={4}
            xs={12}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img
                    src={props.img}
                    alt={props.title}
                    style={{
                        borderRadius: '50%',
                        width: 130,
                        height: 130,
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 1,
                        p: 2,
                    }}
                >
                    <Typography
                        variant='h6'
                        sx={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                        {props.title}
                    </Typography>
                    <Typography
                        variant='body1'
                        component='p'
                        sx={{
                            textAlign: 'center',
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {props.description}
                    </Typography>
                </Box>
            </Box>
        </Grid>
    );
}

export default HomeFeature;
