import { VolumeUp } from '@mui/icons-material';
import useIsMobile from '../../constants/useIsMobile';
import AnswerInfoResponse from '../../responses/AnswerInfo';
import EmptyResponse from '../../responses/EmptyResponse';
import EmptyResponseType from '../../responses/EmptyResponseType';
import { AnswerInfo2EndpointGetHook, AnswerInfo2EndpointGetPromise } from '../../routes/AnswerInfo';
import { Box, FormControl, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { TTS } from '../../generals/speak';
import { QuestionModel } from '../../models/QuestionModel';
import Char from '../../generals/Char';
import SelectedChips from './SelectedChips';
import SelectableChips from './SelectableChips';
import QuestionText from './QuestionText';
import { sendVibration } from '../../constants/vibrate';
import AnswerButtonV2 from '../AnswerButtonV2';
import AnswerInfo from '../AnswerInfo';
import EditQuestion from './EditQuestion';

interface ListeningQuestion extends QuestionModel {
    syllables: any[];
    answer: string;
    translation: string;
    parts: string;
}

interface Props {
    question: ListeningQuestion;
    index: number;
    answer: string;
    originalCount: number;
    answerTransliteration?: string;
    answerDisabledState: boolean;
    setCurrentAnswer: (answer: string) => void;
    handleAnswer: () => void;
    handleAddChip: (id: string) => void;
    handleDeleteChip: (id: string) => void;
    selectedChips: string[];
    setSelectedChips: (chips: string[]) => void;
    chipOrTextState: string;
    setChipOrTextState: any;
    baseLanguage: string;
    targetLanguage: string;
    speak: (text: string, language?: string) => void;
    convertChipToSyllable: (id: string) => string;
    contentPosition: any;
    translations: any;
    previousMistakes: string[];
    lection: string;
    skipQuestion: () => void;
    noMenu: boolean;
    isAdmin?: boolean;
}

function ListeningQuestion(props: Props) {
    useEffect(() => {
        TTS(props.question.question, props.targetLanguage);
        setIsAnswered(false);
    }, [props.question]);

    const [isAnswered, setIsAnswered] = useState(false);

    const [answerInfoState, setAnswerInfoState] = useState<AnswerInfoResponse | EmptyResponseType>(EmptyResponse);
    const isMobile = useIsMobile();

    const answerInfoResponse = AnswerInfo2EndpointGetHook(props.question.id);

    useEffect(() => {
        if (answerInfoResponse.data) {
            setAnswerInfoState(answerInfoResponse);
        }
    }, [answerInfoResponse.data]);

    useEffect(() => {
        setShowContent(false);
        const promise = AnswerInfo2EndpointGetPromise(props.question.id);

        promise.then((response) => {
            setAnswerInfoState(response);
        });
    }, [props.question.id, props.index]);

    const [showContent, setShowContent] = useState<boolean>(false);

    const handleAnswer = () => {
        setShowContent(true);
        setIsAnswered(true);
        props.handleAnswer();
    };

    const removeFirstTwoLines = (text: string) => {
        const lines = text.split('\n');

        if (lines[0].startsWith('#') && lines.length > 2) {
            return text.split('\n').slice(2).join('\n');
        }

        return text;
    };


    return (
        <Box
            sx={props.contentPosition}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                    width: '100%',
                }}
            >
                {props.previousMistakes.length > 0 && props.previousMistakes.includes(props.question.id) && props.originalCount < props.index &&
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        color='error'
                    >
                        {props.translations.previousMistake}
                    </Typography>
                }
                <Typography
                    variant="h6"
                    gutterBottom
                    fontWeight='bold'
                >
                    {props.translations.listenAndWrite}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Char
                        language={props.targetLanguage}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: 2,
                            backgroundColor: useTheme().palette.grey[100],
                            paddingY: 1,
                            paddingLeft: 2,
                            paddingRight: 4,
                            ml: 2,
                            position: 'relative',
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                sendVibration('simple');
                                props.speak(props.question.question, props.targetLanguage);
                            }}
                            color='primary'
                            sx={{
                                cursor: 'pointer',
                            }}
                        >
                            <VolumeUp />
                        </IconButton>
                        <Box
                            sx={{
                                filter: isAnswered ? 'none' : 'blur(7px)',
                                ml: 2,
                            }}
                        >
                            <QuestionText
                                question={props.question.question}
                                questionId={props.question.id}
                                language={props.targetLanguage}
                                baseLanguage={props.baseLanguage}
                                entireTransliteration={props.answerTransliteration}
                                direction='base'
                                entireTranslation={props.question.translation}
                                lection={props.lection}
                                showTranslation={isAnswered}
                                parts={props.question.parts}
                            />
                            {isAnswered &&
                                <>
                                    <EditQuestion
                                        question={props.question}
                                        translations={props.translations}
                                        isAdmin={props.isAdmin}
                                    />
                                    {answerInfoState.status === 200 && answerInfoState.data.question === props.question.id &&
                                        <AnswerInfo
                                            isAdmin={props.isAdmin}
                                        >
                                            {removeFirstTwoLines(answerInfoState.data.info)}
                                        </AnswerInfo>
                                    }
                                </>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
            <FormControl fullWidth>
                {props.chipOrTextState === 'chip' &&
                    <SelectedChips
                        selectedChips={props.selectedChips}
                        convertChipToSyllable={props.convertChipToSyllable}
                        setSelectedChips={props.setSelectedChips}
                        handleDeleteChip={props.handleDeleteChip}
                        deletable={true}
                        draggable={true}
                        handleAddChip={props.handleAddChip}
                        showPinYin={props.targetLanguage === 'chinese' || props.targetLanguage === 'cantonese'}
                        language={props.targetLanguage}
                    />
                }
                {props.chipOrTextState === 'text' &&
                    <TextField
                        fullWidth
                        variant='standard'
                        label={props.translations.answer}
                        value={props.answer}
                        onChange={(event) => props.setCurrentAnswer(event.target.value)}
                        sx={{
                            '& .MuiInput-underline:before': {
                                borderBottom: '1px solid ' + useTheme().palette.divider,
                            },
                            '& .MuiInput-underline:hover:before': {
                                borderBottom: '1px solid ' + useTheme().palette.divider,
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: '1px solid ' + useTheme().palette.divider,
                            },
                            marginTop: 2,
                            '& .MuiInputBase-input': {
                                fontSize: '1.5rem',
                            },
                        }}
                    />
                }
            </FormControl>
            <FormControl fullWidth>
                {props.chipOrTextState === 'chip' &&
                    <SelectableChips
                        handleDeleteChip={props.handleDeleteChip}
                        handleAddChip={props.handleAddChip}
                        selectedChips={props.selectedChips}
                        question={props.question}
                        answerDisabledState={props.answerDisabledState}
                        showPinYin={props.targetLanguage === 'chinese' || props.targetLanguage === 'cantonese'}
                        language={props.targetLanguage}
                    />
                }
            </FormControl>
            <AnswerButtonV2
                answer={handleAnswer}
                currentAnswer={props.answer}
                translations={props.translations}
                baseLanguage={props.baseLanguage}
                targetLanguage={props.targetLanguage}
                speak={props.speak}
                skipQuestion={props.skipQuestion}
                correctAnswer={props.question.answer}
            />
        </Box >
    );
}

export default ListeningQuestion;
