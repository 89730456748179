import { InfoRounded } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import LairnerModal from '../generals/LairnerModal';
import useTranslationState from '../generals/useTranslationState';

interface Props {
    isAdmin?: boolean;
    children: string;
}

function AnswerInfo(props: Props) {
    const [open, setOpen] = useState(false);

    const translations = useTranslationState({
        answerInfo: '',
    });

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                right: props.isAdmin ? 30 : 0,
            }}
        >
            <IconButton
                onClick={() => setOpen(!open)}
                color="primary"
            >
                <InfoRounded />
            </IconButton>
            <LairnerModal
                open={open}
                close={() => setOpen(false)}
                title={translations.answerInfo}
            >
                <Typography
                    variant="body1"
                    color="primary.contrastText"
                >
                    <ReactMarkdown>
                        {props.children}
                    </ReactMarkdown>
                </Typography>
            </LairnerModal>
        </Box>
    );
}

export default AnswerInfo;
