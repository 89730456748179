import { Alert, Box, Button, Chip, Container, FormControl, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useIsMobile from './constants/useIsMobile';
import useEndpoint from './generals/useEndpoint';
import { BadgeModel } from './models/BadgeModel';
import EditUserResponse from './responses/EditUser';
import StateResponse from './responses/State';
import { EditUser2EndpointPostPromise } from './routes/EditUser';
import { Language2EndpointGetHook } from './routes/Language';
import { Logout2EndpointPostPromise } from './routes/Logout';
import { State2EndpointGetHook } from './routes/State';

interface BadgeModelWithLabelAndDesc extends BadgeModel {
    label: string;
    description: string;
}

function Profile() {
    const [translations, setTranslations] = useState({
        logout: '',
        oldPassword: '',
        statistics: '',
        profile: '',
        options: '',
        shop: '',
        yourBadges: '',
        successSave: '',
        name: '',
        email: '',
        description: '',
        password: '',
        save: '',
        payment: '',
        support: '',
        errorSave: '',
        faq: '',
        pleaseEnterOldPassword: '',
        pleaseEnterPasswordWithValidLength: '',
    });

    const isMobile = useIsMobile();

    const translationsResponse = Language2EndpointGetHook(JSON.stringify(Object.keys(translations)));

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [userState, setUserState] = useState<any>({});

    useEffect(() => {
        if (translationsResponse.data) {
            setTranslations(translationsResponse.data.translations);
            setIsLoggedIn(translationsResponse.userState.isLogin);
            setUserState(translationsResponse.userState.userModel);
            setIsLoaded(true);
        }
    }, [translationsResponse.data]);

    const navigate = useNavigate();

    if (!isLoggedIn && isLoaded) {
        navigate('/');
    }

    const [nameState, setNameState] = useState('');
    const [emailState, setEmailState] = useState('');
    const [descriptionState, setDescriptionState] = useState('');
    const [oldPasswordState, setOldPasswordState] = useState('');
    const [passwordState, setPasswordState] = useState('');

    const [stateState, setStateState] = useEndpoint<StateResponse>(State2EndpointGetHook());

    useEffect(() => {
        if (isLoaded) {
            setNameState(userState.name);
            setEmailState(userState.email);
            setDescriptionState(userState.description);
        }
    }, [isLoaded]);

    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success' as 'success' | 'info' | 'warning' | 'error');

    const saveAll = () => {
        const promise = EditUser2EndpointPostPromise(
            nameState,
            oldPasswordState,
            passwordState,
            descriptionState,
        );

        promise.then((response) => {
            response.json().then((data: EditUserResponse) => {
                if (data.data.success) {
                    setAlertSeverity('success');
                    setAlertText(translations.successSave);
                    setShowAlert(true);
                } else {
                    setAlertSeverity('error');
                    setAlertText(translations.errorSave);
                    setShowAlert(true);
                }
            });
        });
    };

    const logout = () => {
        const promise = Logout2EndpointPostPromise();

        promise.then(() => {
            window.location.href = '/';
        });
    };

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: 'calc(100vh - 80px)',
            }}
        >
            <Container
                maxWidth="lg"
                sx={{
                    paddingTop: { xs: 2, md: 8 },
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {translations.profile}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        flexWrap: 'wrap',
                        mb: 2,
                    }}
                >
                    {stateState.status === 200 && stateState.data.badges.map((badge: BadgeModelWithLabelAndDesc, index: number) => (
                        <Tooltip
                            key={index}
                            title={badge.description}
                        >
                            <Chip
                                label={badge.label}
                                sx={{
                                    userSelect: 'none',
                                    cursor: 'default',
                                }}
                            />
                        </Tooltip>
                    ))}
                </Box>
                <Typography
                    variant="h6"
                >
                    {emailState}
                </Typography>
                {showAlert &&
                    <Alert
                        severity={alertSeverity}
                    >
                        {alertText}
                    </Alert>
                }
                <FormControl fullWidth>
                    <TextField
                        label={translations.name}
                        value={nameState}
                        onChange={(e) => setNameState(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                        label={translations.description}
                        value={descriptionState}
                        onChange={(e) => setDescriptionState(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </FormControl>
                <FormControl
                    sx={{
                        my: 2,
                    }}
                    fullWidth
                >
                    <TextField
                        label={translations.oldPassword}
                        value={oldPasswordState}
                        type="password"
                        onChange={(e) => setOldPasswordState(e.target.value)}
                        autoComplete="current-password"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label={translations.password}
                        value={passwordState}
                        autoComplete="new-password"
                        type="password"
                        onChange={(e) => setPasswordState(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    {passwordState.length > 0 && passwordState.length < 8 &&
                        <Alert
                            severity="error"
                        >
                            {translations.pleaseEnterPasswordWithValidLength}
                        </Alert>
                    }
                </FormControl>
                <FormControl>
                    <Button
                        variant="contained"
                        onClick={saveAll}
                        sx={{
                            width: 'fit-content',
                        }}
                    >
                        {translations.save}
                    </Button>
                </FormControl>
            </Container>
            <Container
                maxWidth="lg"
                sx={{
                    py: 4,
                }}
            >
                <FormControl
                    sx={{
                        marginTop: 4,
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={logout}
                        color="error"
                        sx={{
                            width: 'fit-content',
                        }}
                    >
                        {translations.logout}
                    </Button>
                </FormControl>
            </Container>
        </Box>
    );
}

export default Profile;
