export const FLAGS = [
    'german',
    'spanish',
    'turkish',
    'russian',
    'armenian',
    'dari',
    'pashto',
    'persian',
    'chinese',
    'french',
    'arabic',
    'italian',
    'english',
    'portuguese',
    'dutch',
    'czech',
    'japanese',
    'swedish',
    'polish',
    'korean',
    'thai',
    'hebrew',
    'afrikaans',
    'albanian',
    'bengali',
    'bulgarian',
    'cantonese',
    'croatian',
    'finnish',
    'greek',
    'hindi',
    'hungarian',
    'icelandic',
    'igbo',
    'indonesian',
    'latvian',
    'lithuanian',
    'mongolian',
    'punjabi',
    'romanian',
    'serbian',
    'slovak',
    'slovenian',
    'somali',
    'swahili',
    'tagalog',
    'tamil',
    'ukrainian',
    'urdu',
    'yoruba',
    'norwegian',
    'vietnamese',
];
