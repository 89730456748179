import { CloseRounded } from '@mui/icons-material';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import FunFactResponse from '../responses/FunFact';
import { FunFact2EndpointGetHook, FunFact2EndpointGetPromise } from '../routes/FunFact';
import Char from './Char';
import useFrequentEndpoint from './useFrequentEndpoint';

interface Props {
    baseLanguage: string;
    targetLanguage: string;
}

function FunFact(props: Props) {
    const [funFact, setFunFact] = useFrequentEndpoint<FunFactResponse, any>(FunFact2EndpointGetHook(props.baseLanguage, props.targetLanguage), FunFact2EndpointGetPromise, 60, props.baseLanguage, props.targetLanguage);

    const [showFunFact, setShowFunFact] = useState(localStorage.getItem('showFunFact') !== 'false');

    const hideFunFact = () => {
        localStorage.setItem('showFunFact', 'false');
        setShowFunFact(false);
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 100,
                right: 10,
            }}
        >
            {funFact.status === 200 && showFunFact &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'end',
                    }}
                >
                    <Box
                        sx={{
                            padding: 2,
                            width: '16vw',
                            backgroundColor: useTheme().palette.grey[200],
                            boxShadow: 1,
                            borderRadius: 2,
                            position: 'relative',
                        }}
                    >
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }}
                            onClick={() => hideFunFact()}
                        >
                            <CloseRounded />
                        </IconButton>
                        <Typography
                            variant='body2'
                        >
                            <ReactMarkdown>
                                {funFact.data.fact}
                            </ReactMarkdown>
                        </Typography>
                    </Box>
                    <Char
                        width='100px'
                        height='100px'
                        language={props.targetLanguage}
                    />
                </Box>
            }
        </Box>
    );
}

export default FunFact;
