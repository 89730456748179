import { AdminPanelSettingsRounded, CheckRounded, SkipNextRounded } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useNavigate } from 'react-router-dom';
import { appleButton } from './constants/appleButton';
import useIsMobile from './constants/useIsMobile';
import { theme } from './generals/theme';
import useEndpoint from './generals/useEndpoint';
import useTranslationState from './generals/useTranslationState';
import { LectionModel } from './models/LectionModel';
import { getHost } from './modules/api/client';
import LessonResponse from './responses/Lesson';
import { Complete2EndpointPostPromise } from './routes/Complete';
import { Lesson2EndpointGetHook } from './routes/Lesson';

interface LectionModelWithParts extends LectionModel {
    parts: number;
    isCompleted: boolean;
    isAvailable: boolean;
    isPossible: boolean;
    completion: number;
    isUsable: boolean;
}

interface Props {
    lectionIndex: number;
    lection: LectionModelWithParts;
    link: string;
    baseLanguage: string;
    targetLanguage: string;
    doneCheck?: boolean;
}

// This is a mui card with "Lection X", the title
// Then there is the learnText and a startButton if .isAvailable is true
// And also every card has a Recap part with a recap button which is enabled if lection.parts === lessonState.data.completion

function LearningPlanCard(props: Props) {
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const [lessonState, setLessonState] = useEndpoint<LessonResponse>(Lesson2EndpointGetHook(props.baseLanguage, props.targetLanguage, props.lection.id));

    const translations = useTranslationState({
        start: '',
        shortGrammarLesson: '',
        lectionX: '',
        adminJump: '',
        recap: '',
        notAvailable: '',
        startRecap: '',
        unitX: '',
        readyForAJumpDoAQuickTestToSkipTohere: '',
    });

    // props.lection.readme.slice(0, 200) + (props.lection.readme.length > 200 ? '...' : '')
    const getMarkdownText = () => {
        if (lessonState.status !== 200) {
            return '';
        }

        // Remove the first line of .readme 
        const readme = lessonState.data.readme.split('\n').slice(1).join('\n');

        return readme.slice(0, 200) + (readme.length > 200 ? '...' : '');
    };

    return (
        <>
            {lessonState.status === 200 &&
                <Box
                    id={'lection' + props.lectionIndex.toString()}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: props.lection.isCompleted
                            ? theme.palette.success.dark + '1a'
                            : (props.lection.isAvailable
                                ? theme.palette.grey[200]
                                : theme.palette.grey[50]),
                        borderRadius: 2,
                        marginTop: 2,
                        position: 'relative',
                        // border: props.lection.isCompleted
                        //     ? '1px solid ' + theme.palette.success.main
                        //     : 'none',
                    }}
                >
                    {props.lection.isCompleted &&
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 5,
                                right: 5,
                            }}
                        >
                            <CheckRounded
                                sx={{
                                    fontSize: 30,
                                    color: theme.palette.success.main,
                                }}
                            />
                        </Box>
                    }
                    <Box
                        sx={{
                            p: 2,
                            borderBottom: '0.1px solid ' + theme.palette.divider,
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                            }}
                        >
                            <img
                                src={getHost() + '/image.php?file=' + lessonState.data.image}
                                alt={props.lection.lection}
                                style={{
                                    width: isMobile ? 50 : 100,
                                    height: isMobile ? 50 : 100,
                                    borderRadius: 8,
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    variant={isMobile ? 'subtitle1' : 'h6'}
                                >
                                    {translations.lectionX.replace('[X]', props.lectionIndex.toString())}
                                </Typography>
                                <Typography
                                    variant={isMobile ? 'subtitle2' : 'subtitle1'}
                                >
                                    {props.lection.lection}
                                </Typography>
                                {lessonState.data.isGrammar &&
                                    <Typography
                                        variant={isMobile ? 'body2' : 'body1'}
                                    >
                                        {translations.shortGrammarLesson}
                                    </Typography>
                                }
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                marginLeft: 'auto',
                            }}
                        >
                            {!props.lection.isAvailable &&
                                <Tooltip
                                    title={translations.readyForAJumpDoAQuickTestToSkipTohere}
                                >
                                    <IconButton
                                        color='primary'
                                        onClick={() => {
                                            navigate('/courses/' + props.baseLanguage + '/' + props.targetLanguage + '/intro/lesson');
                                        }}
                                    >
                                        <SkipNextRounded />
                                    </IconButton>
                                </Tooltip>
                            }
                            {lessonState.userState.userModel.role === 'admin' &&
                                <Tooltip
                                    title={translations.adminJump}
                                >
                                    <IconButton
                                        color='primary'
                                        onClick={() => {
                                            const promise = Complete2EndpointPostPromise(props.baseLanguage, props.targetLanguage, props.lection.id);

                                            promise.then(() => {
                                                navigate(0);
                                            });
                                        }}
                                    >
                                        <AdminPanelSettingsRounded />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            p: 2,
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {!props.lection.isCompleted && props.lection.isAvailable &&
                                <Typography
                                    variant='body1'
                                >
                                    {translations.unitX.replace('[X]', (props.lection.completion + 1).toString())}
                                </Typography>
                            }
                            <Typography
                                variant='body1'
                                sx={{
                                    maxWidth: 400,
                                    flexGrow: 1,
                                }}
                            >
                                <ReactMarkdown>
                                    {getMarkdownText()}
                                </ReactMarkdown>
                            </Typography>
                        </Box>
                        {!props.lection.isCompleted &&
                            <Box
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            >
                                <Button
                                    variant='contained'
                                    color='primary'
                                    disabled={!props.lection.isAvailable}
                                    onClick={() => {
                                        if (props.lection.isUsable) {
                                            navigate(props.link);
                                        }
                                    }}
                                    sx={{
                                        ...appleButton,
                                    }}
                                >
                                    {props.lection.isUsable ? translations.start : translations.notAvailable}
                                </Button>
                            </Box>
                        }
                    </Box>
                    {!lessonState.data.isGrammar &&
                        <Box
                            sx={{
                                display: 'flex',
                                borderTop: '0.1px solid ' + theme.palette.divider,
                                flexDirection: 'row',
                                p: 2,
                            }}
                        >
                            <Typography
                                variant='h6'
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                {translations.recap}
                            </Typography>
                            <Button
                                variant='contained'
                                color='primary'
                                disabled={props.lection.parts !== lessonState.data.completion && props.lection.isCompleted === false}
                                onClick={() => {
                                    navigate(props.link);
                                }}
                                sx={{
                                    ...appleButton,
                                }}
                            >
                                {translations.startRecap}
                            </Button>
                        </Box>
                    }
                </Box>
            }
        </>
    );
}

export default LearningPlanCard;
