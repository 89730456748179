import { Edit } from '@mui/icons-material';
import { Box, Button, FormControl, IconButton, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import LairnerModal from '../../generals/LairnerModal';
import { QuestionModel } from '../../models/QuestionModel';
import { EditQuestion2EndpointPostPromise } from '../../routes/EditQuestion';

interface ExtendedQuestion extends QuestionModel {
    syllables: any[];
    question: string;
    answer: string;
    parts: string;
}

interface Props {
    question: ExtendedQuestion;
    translations: any;
    isAdmin?: boolean;
}

function EditQuestion(props: Props) {
    const [editMode, setEditMode] = useState(false);

    const [questionText, setQuestionText] = useState(props.question.question);
    const [answerText, setAnswerText] = useState(props.question.answer);

    const editQuestion = () => {
        const promise = EditQuestion2EndpointPostPromise(
            props.question.id,
            questionText,
            answerText,
        );

        promise.then((response) => {
            if (response.status === 200) {
                setEditMode(false);
            }
        });
    };

    useEffect(() => {
        setQuestionText(props.question.question);
        setAnswerText(props.question.answer);
    }, [props.question.id]);

    return (
        <>
            {props.isAdmin === true &&
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }}
                >
                    <IconButton
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={() => setEditMode(!editMode)}
                    >
                        <Edit />
                    </IconButton>
                    <LairnerModal
                        open={editMode}
                        close={() => setEditMode(false)}
                        title={props.translations.editQuestion}
                    >
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <TextField
                                label={props.translations.question}
                                value={questionText}
                                onChange={(e) => setQuestionText(e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <TextField
                                label={props.translations.answer}
                                value={answerText}
                                onChange={(e) => setAnswerText(e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={editQuestion}
                            >
                                {props.translations.save}
                            </Button>
                        </FormControl>
                    </LairnerModal>
                </Box>
            }
        </>
    );
}

export default EditQuestion;
