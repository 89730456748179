import React from 'react';
import { Container, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import useTranslationState from './generals/useTranslationState';

function PrivacyPolicy() {
    const translations = useTranslationState({
        privacyPolicy: '',
    });

    const privacyContent = `
We at lairner (operated by Alexander Bösing lairner GbR) value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, and safeguard your information.

## Data We Collect
- **Cookies**: We use cookies to track user progress and enhance functionality.  
- **Account Data**: When you sign up, we collect your **email address**, **name**, and **password** to create and manage your account.  
- **Technical Data (Potential Future Use)**: In the near future, we may also automatically collect information such as **IP addresses**, **browser type**, and **device information** for analytics and security purposes.

## Use of Data
1. **Functionality**: We process the collected data (e.g., cookies, name, email) to provide and maintain our services, including user account management and progress tracking.  
2. **Analytics & Ads**: We plan to use certain data (such as cookies and technical identifiers) for **Google Ads** and analytics to improve our services and reach potential users.  
3. **Communication**: We may contact you regarding updates, security notifications, or other service-related messages.

## Legal Basis
- **Consent**: When you voluntarily provide personal information or accept cookies, we rely on your consent to process your data.  
- **Legitimate Interests**: We may process certain technical data to maintain security and improve the functionality of our services.

## Data Sharing
- **Third-Party Services**: We may share data with Google Ads (and potentially other analytics providers) as part of our advertising and performance measurement efforts.  
- **Legal Compliance**: We may disclose data if required by law or court order.

## Data Retention
We store personal information only for as long as necessary to fulfill the purposes for which we collected it, or as required by applicable laws.

## Your Rights
Under applicable data protection laws, you may have the right to:
- Request access to and obtain a copy of your personal data.
- Request rectification or erasure of your personal data.
- Object to or restrict the processing of your personal data.
- Lodge a complaint with a supervisory authority, if applicable in your jurisdiction.

## Contact Us
If you have any questions or concerns about this Privacy Policy or your personal data, please contact us at:  
**Email**: [privacy@lairner.app](mailto:privacy@lairner.app)

## Changes to This Privacy Policy
We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any changes will be posted on this page with an updated revision date.

`;

    return (
        <Container
            maxWidth="md"
            sx={{
                paddingTop: { xs: 2, md: 8 },
                minHeight: 'calc(100vh - 80px)',
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    fontWeight: 'bold',
                    marginBottom: 2,
                }}
            >
                {translations.privacyPolicy}
            </Typography>
            <ReactMarkdown
            >
                {privacyContent}
            </ReactMarkdown>
        </Container>
    );
}

export default PrivacyPolicy;
