import { AutoGraphRounded } from '@mui/icons-material';
import { Alert, Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useNavigate } from 'react-router-dom';
import useIsMobile from './constants/useIsMobile';
import { theme } from './generals/theme';
import useEndpoint from './generals/useEndpoint';
import useFrequentEndpoint from './generals/useFrequentEndpoint';
import useTranslationState from './generals/useTranslationState';
import { LectionModel } from './models/LectionModel';
import ActivitiesResponse from './responses/Activities';
import ExperienceResponse from './responses/Experience';
import LessonResponse from './responses/Lesson';
import { Activities2EndpointGetHook, Activities2EndpointGetPromise } from './routes/Activities';
import { Experience2EndpointGetHook } from './routes/Experience';
import { Lesson2EndpointGetHook } from './routes/Lesson';
import LaterVocabCard from './today/LaterVocabCard';
import LessonCard from './today/LessonCard';
import NowVocabCard from './today/NowVocabCard';
import RecapCard from './today/RecapCard';

interface LectionModelWithParts extends LectionModel {
    parts: number;
    isCompleted: boolean;
    isAvailable: boolean;
    isPossible: boolean;
    completion: number;
}

interface Props {
    baseLanguage: string;
    targetLanguage: string;
    todayLection: LectionModelWithParts;
    todayLectionIndex: number;
    vocabCount: number;
    setTab: (tab: string) => void;
    setTrain: (train: boolean) => void;
}

function Today(props: Props) {
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const translations = useTranslationState({
        today: '',
        quickDoALessonOrSomeVocabInOrderToNotLoseYourOnGoingStreak: '',
        lectionX: '',
        startLesson: '',
        activities: '',
        info: '',
        youHaveXVocabsToDo: '',
        youHaveSomeOpenVocabWeRecommendYouToDoThemBeforeContinuingWithTheNextUnit: '',
        yourProgress: '',
        doVocab: '',
        experience: '',
        recap: '',
        startRecap: '',
        nowYouWillHaveToRecapThisLectionToContinueWithTheNextOne: '',
        afterAllUnitsYouWillBeAbleToRecapThisLection: '',
        unitX: '',
        thisIsAShortGrammarLessonToFreshenUpYourKnowledge: '',
    });

    const [activities, setActivities] = useFrequentEndpoint<ActivitiesResponse, any>(Activities2EndpointGetHook('7'), Activities2EndpointGetPromise, 30, '7');
    const [experience, setExperience] = useEndpoint<ExperienceResponse>(Experience2EndpointGetHook());

    const [todayLessonState, setTodayLessonState] = useEndpoint<LessonResponse>(Lesson2EndpointGetHook(props.baseLanguage, props.targetLanguage, props.todayLection.id));

    const generateYMDForTheLastSevenDays = (): string[] => {
        const result: string[] = [];

        for (let i = 0; i < 7; i++) {
            const date = new Date();
            date.setDate(date.getDate() - i);

            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();

            result.push(year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0'));
        }

        return result;
    };

    const getWeekdayShort = (date: string): string => {
        const dateObj = new Date(date);
        const weekdays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

        return weekdays[dateObj.getDay()];
    };

    const shouldDoVocab = (): boolean => props.vocabCount > 50;

    const couldDoButDoesntHaveToVocab = (): boolean => props.vocabCount > 0 && props.vocabCount <= 50;

    const canDoNormalLesson = (): boolean => {
        if (todayLessonState.status !== 200) {
            return false;
        }

        return props.todayLection.parts !== todayLessonState.data.completion && props.todayLection.isCompleted !== true;
    };

    const canDoRecap = (): boolean => {
        if (todayLessonState.status !== 200) {
            return false;
        }

        return props.todayLection.parts === todayLessonState.data.completion || props.todayLection.isCompleted;
    };

    const [streakDays, setStreakDays] = useState<string[]>([]);

    useEffect(() => {
        if (activities.status === 200) {
            const streakDays: string[] = [];

            let streak = 0;

            for (let i = 0; i < 7; i++) {
                if (activities.data.activities[generateYMDForTheLastSevenDays()[i]] > 0) {
                    streak++;
                    streakDays.push(generateYMDForTheLastSevenDays()[i]);
                } else {
                    break;
                }
            }

            setStreakDays(streakDays);
        }
    }, [activities]);

    const [showDoSomethingAlert, setShowDoSomethingAlert] = useState(false);

    useEffect(() => {
        if (activities.status === 200) {
            const date = new Date();

            const today = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');

            if (activities.data.streak !== 0 && date.getHours() >= 18 && activities.data.activities[today] === 0) {
                setShowDoSomethingAlert(true);
            }
        }
    }, [activities.data.streak]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'calc(100vh - 144px)',
                p: 2,
            }}
        >
            <Typography
                variant='h4'
                sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                }}
            >
                {translations.today}
            </Typography>
            {showDoSomethingAlert &&
                <Alert
                    severity='info'
                    sx={{
                        marginTop: 2,
                    }}
                    onClose={() => setShowDoSomethingAlert(false)}
                >
                    {translations.quickDoALessonOrSomeVocabInOrderToNotLoseYourOnGoingStreak}
                </Alert>
            }
            <Grid
                container
                spacing={2}
                sx={{
                    marginY: 2,
                }}
            >
                {todayLessonState.status === 200 && shouldDoVocab() &&
                    <Grid
                        item
                        xs={12}
                        md={canDoNormalLesson() && shouldDoVocab() ? 6 : 3}
                    >
                        <NowVocabCard
                            baseLanguage={props.baseLanguage}
                            targetLanguage={props.targetLanguage}
                            vocabCount={props.vocabCount}
                            isMobile={isMobile}
                            navigate={navigate}
                            translations={translations}
                            canDoNormalLesson={canDoNormalLesson}
                            shouldDoVocab={shouldDoVocab}
                            setTab={props.setTab}
                            setTrain={props.setTrain}
                        />
                    </Grid>
                }
                {todayLessonState.status === 200 &&
                    <Grid
                        item
                        xs={12}
                        md={canDoNormalLesson() && !shouldDoVocab() ? 6 : 3}
                    >
                        <LessonCard
                            baseLanguage={props.baseLanguage}
                            targetLanguage={props.targetLanguage}
                            todayLection={props.todayLection}
                            todayLectionIndex={props.todayLectionIndex}
                            translations={translations}
                            navigate={navigate}
                            canDoNormalLesson={canDoNormalLesson}
                            shouldDoVocab={shouldDoVocab}
                            todayLessonState={todayLessonState}
                        />
                    </Grid>
                }
                {todayLessonState.status === 200 && couldDoButDoesntHaveToVocab() &&
                    <Grid
                        item
                        xs={12}
                        md={3}
                    >
                        <LaterVocabCard
                            baseLanguage={props.baseLanguage}
                            targetLanguage={props.targetLanguage}
                            vocabCount={props.vocabCount}
                            isMobile={isMobile}
                            navigate={navigate}
                            translations={translations}
                            canDoNormalLesson={canDoNormalLesson}
                            setTab={props.setTab}
                            setTrain={props.setTrain}
                        />
                    </Grid>
                }
                {todayLessonState.status === 200 && !todayLessonState.data.isGrammar &&
                    <Grid
                        item
                        xs={12}
                        md={canDoNormalLesson() ? 3 : 6}
                    >
                        <RecapCard
                            baseLanguage={props.baseLanguage}
                            targetLanguage={props.targetLanguage}
                            todayLection={props.todayLection}
                            canDoRecap={canDoRecap}
                            translations={translations}
                            navigate={navigate}
                        />
                    </Grid>
                }
            </Grid>
            {todayLessonState.status === 200 && todayLessonState.data.readme !== '' &&
                <Box
                    sx={{
                        marginTop: 4,
                        width: { xs: '100%', md: 'auto' },
                    }}
                >
                    <Typography
                        variant='h4'
                        sx={{
                            fontWeight: 'bold',
                            color: theme.palette.primary.main,
                        }}
                        gutterBottom
                    >
                        {translations.info}
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.grey[200],
                            borderRadius: 2,
                            width: '100%',
                            height: 400,
                            overflowY: 'auto',
                        }}
                    >
                        <Typography
                            variant='body1'
                            sx={{
                                paddingX: 2,
                            }}
                        >
                            <ReactMarkdown
                            >
                                {todayLessonState.data.readme}
                            </ReactMarkdown>
                        </Typography>
                    </Box>
                </Box>
            }
            <Box
                sx={{
                    marginTop: 4,
                    width: { xs: '100%', md: 'auto' },
                }}
            >
                <Typography
                    variant='h4'
                    sx={{
                        fontWeight: 'bold',
                        color: theme.palette.primary.main,
                    }}
                    gutterBottom
                >
                    {translations.yourProgress}
                </Typography>
                <Box
                    sx={{
                        p: 2,
                        backgroundColor: theme.palette.grey[200],
                        width: { xs: '100%', md: 'fit-content' },
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    <Box
                        sx={{
                            borderRight: { xs: 'none', md: '1px solid ' + theme.palette.grey[400] },
                            borderBottom: { xs: '1px solid ' + theme.palette.grey[400], md: 'none' },
                            pr: { xs: 0, md: 2 },
                            pb: { xs: 2, md: 0 },
                        }}
                    >
                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                color: theme.palette.primary.main,
                            }}
                        >
                            {translations.activities}
                        </Typography>
                        {activities.status === 200 &&
                            <Grid
                                container
                                spacing={2}
                            >
                                {generateYMDForTheLastSevenDays().reverse().map(day => (
                                    <Grid
                                        item
                                        xs={12 / 7}
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: day === generateYMDForTheLastSevenDays()[0]
                                                    ? theme.palette.background.paper
                                                    : 'inherit',
                                                border: streakDays.includes(day) ? '1px solid ' + theme.palette.primary.main : 'none',
                                                p: 1,
                                                borderRadius: 1,
                                            }}
                                        >
                                            <Typography
                                                variant='body1'
                                            >
                                                {getWeekdayShort(day)}
                                            </Typography>
                                            <Typography
                                                variant='caption'
                                            >
                                                {activities.data.activities[day] ?? 0}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        }
                    </Box>
                    <Box
                        sx={{
                            pl: { xs: 0, md: 2 },
                            pt: { xs: 2, md: 0 },
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                color: theme.palette.primary.main,
                            }}
                        >
                            {translations.experience}
                        </Typography>
                        {experience.status === 200 &&
                            <Typography
                                variant='body1'
                                sx={{
                                    marginTop: 'auto',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                <AutoGraphRounded
                                    sx={{
                                    }}
                                />
                                {experience.data.experience}
                            </Typography>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Today;
