import { routes } from './routes-generated';

import { GetWithHook, GetWithPromise, PostWithPromise } from '../modules/api/client';

export function Signup2EndpointGetHook(
) {
    return GetWithHook(routes.Signup, {
    });
}

export function Signup2EndpointGetPromise(
) {
    return GetWithPromise(routes.Signup, {
    });
}

export function Signup2EndpointPostPromise(
    param_name: string, 
    param_email: string, 
    param_password: string, 
    param_sendMail: string, 
) {
    return PostWithPromise(routes.SignupPost, {
        name: param_name, 
        email: param_email, 
        password: param_password, 
        sendMail: param_sendMail, 
    });
}

