export const routes = {
    ListTables: '/admin/db/listTables.json',
    SelectTable: '/admin/db/selectTable.json?table={table}&page={page}&perPage={perPage}',
    DBUpdate: '/admin/db/update.json?table={table}&id={id}&column={column}&value={value}',
    Chat: '/chatbot/chat.json?chatbot={chatbot}&message={message}',
    MissionChat: '/chatbot/mission/chat.json?chatbot={chatbot}&message={message}',
    ListChatbots: '/chatbots/list.json?isMission={isMission}&course={course}',
    Alphabet: '/course/alphabet.json?course={course}',
    Complete: '/course/complete.json?baseLanguage={baseLanguage}&targetLanguage={targetLanguage}&lection={lection}',
    CreateCourse: '/course/create.json?baseLanguage={baseLanguage}&targetLanguage={targetLanguage}&lectionCount={lectionCount}&model={model}&thinkModel={thinkModel}',
    FunFact: '/course/funfact.json?baseLanguage={baseLanguage}&targetLanguage={targetLanguage}',
    GrammarQuestions: '/course/grammar/questions.json?course={course}',
    Lections: '/course/lections.json?course={course}',
    Lesson: '/course/lesson.json?baseLanguage={baseLanguage}&targetLanguage={targetLanguage}&lection={lection}',
    Comment: '/course/lesson/comment.json?question={question}&comment={comment}&parent={parent}',
    Comments: '/course/lesson/comments.json?question={question}',
    Continue: '/course/lesson/continue.json?baseLanguage={baseLanguage}&targetLanguage={targetLanguage}&lection={lection}&mistakes={mistakes}',
    Check: '/course/lesson/question/check.json?question={question}&answer={answer}&lection={lection}&isReverse={isReverse}',
    EditQuestion: '/course/lesson/question/edit.json?questionId={questionId}&question={question}&primaryAnswer={primaryAnswer}',
    GetQuestionTranslations: '/course/lesson/question/getTranslations.json?question={question}',
    AnswerInfo: '/course/lesson/question/info.json?question={question}',
    Report: '/course/lesson/question/report.json?question={question}&answer={answer}&reason={reason}',
    Questions: '/course/lesson/questions.json?baseLanguage={baseLanguage}&targetLanguage={targetLanguage}&lection={lection}&isSupported={isSupported}',
    Find: '/courses/find.json',
    Join: '/courses/join.json?baseLanguage={baseLanguage}&targetLanguage={targetLanguage}',
    Courses: '/courses/list.json?user={user}',
    Edit: '/edit.json?entity={entity}&id={id}',
    Image: '/image.json?file={file}',
    Language: '/language.json?text={text}',
    HasSetLanguage: '/language/hasSet.json?language={language}',
    Logout: '/logout.json',
    AddCustom: '/phases/addCustom.json?baseWord={baseWord}&targetWord={targetWord}&transliteration={transliteration}&course={course}',
    ContinueVocab: '/phases/continue.json?phase={phase}&correct={correct}',
    PhasesCount: '/phases/count.json?baseLanguage={baseLanguage}&targetLanguage={targetLanguage}',
    DeleteCustom: '/phases/deleteCustom.json?baseWord={baseWord}&targetWord={targetWord}&course={course}',
    GetVocabTranslation: '/phases/getVocab.json?word={word}&lection={lection}&direction={direction}',
    Phases: '/phases/list.json?baseLanguage={baseLanguage}&targetLanguage={targetLanguage}',
    Ping: '/ping.json?pingMessage={pingMessage}',
    TTS: '/tts.json?text={text}&language={language}',
    Activities: '/user/activities.json?lastDays={lastDays}',
    Anon: '/user/anon.json?baseLanguage={baseLanguage}&targetLanguage={targetLanguage}',
    ChangeLanguage: '/user/changeLanguage.json?language={language}',
    EditUser: '/user/edit.json?name={name}&oldPassword={oldPassword}&password={password}&description={description}',
    Exists: '/user/exists.json?email={email}',
    Experience: '/user/experience.json',
    Login: '/user/login.json?email={email}',
    Password: '/user/password.json?oldPassword={oldPassword}&password={password}&passwordConfirm={passwordConfirm}',
    Signup: '/user/signup.json',
    State: '/user/state.json',
    Streak: '/user/streak.json',
    Verify: '/user/verify.json?email={email}&code={code}&pin={pin}',
    DBUpdatePost: '/admin/db/update.json',
    ChatPost: '/chatbot/chat.json',
    MissionChatPost: '/chatbot/mission/chat.json',
    CompletePost: '/course/complete.json',
    CreateCoursePost: '/course/create.json',
    CommentPost: '/course/lesson/comment.json',
    ContinuePost: '/course/lesson/continue.json',
    EditQuestionPost: '/course/lesson/question/edit.json',
    ReportPost: '/course/lesson/question/report.json',
    JoinPost: '/courses/join.json',
    EditPost: '/edit.json',
    HasSetLanguagePost: '/language/hasSet.json',
    LogoutPost: '/logout.json',
    AddCustomPost: '/phases/addCustom.json',
    ContinueVocabPost: '/phases/continue.json',
    DeleteCustomPost: '/phases/deleteCustom.json',
    PingPost: '/ping.json',
    AnonPost: '/user/anon.json',
    ChangeLanguagePost: '/user/changeLanguage.json',
    EditUserPost: '/user/edit.json',
    LoginPost: '/user/login.json',
    PasswordPost: '/user/password.json',
    SignupPost: '/user/signup.json',
    VerifyPost: '/user/verify.json',
};
